<div class="skeleton-container">
  <div class="skeleton-header">
    <div class="skeleton-bar skeleton-text header-title"></div>
    <div class="skeleton-bar skeleton-badge"></div>
  </div>
  <div class="skeleton-body">
    <div class="skeleton-bar skeleton-text line"></div>
    <div class="skeleton-bar skeleton-text line"></div>
    <div class="skeleton-info">
      <div class="skeleton-bar skeleton-amount"></div>
      <div class="skeleton-bar skeleton-amount"></div>
    </div>
    <div class="skeleton-actions">
      <div class="skeleton-bar skeleton-button"></div>
      @if (!isContract) {
        <div class="skeleton-bar skeleton-button"></div>
      }
    </div>
  </div>
</div>
