<div class="column">
  <span class="label">{{ "needsSummary.startPayoutAge.label" | fms }}</span>
  <app-start-payout-age-form-field class="fit-content-select"></app-start-payout-age-form-field>
</div>

@if (firstYearTotal$ | async; as firstYearTotal) {
  <div class="column">
    <span class="label">{{ "needsSummary.firstYearTotal.label" | fms }}</span>
    <div class="value">
      <span>{{ firstYearTotal | currencyFormatterPipe: "end" }}</span>
    </div>
  </div>
}

@if (percOfSalaryFirstYear$ | async; as percOfSalaryFirstYear) {
  @if (!(hasNonInflationAdjustedEngagement$ | async)) {
    <div class="column">
      <span class="label">
        {{
          "needsSummary.percOfSalary.label"
            | fms: { args: { salary: annualGrossIncome$ | async | currencyFormatterPipe: "end" } }
        }}
      </span>
      <div class="value">
        <span>{{ percOfSalaryFirstYear }}%</span>
      </div>
    </div>
  }
}
