@switch (state$ | async) {
  @case (State.HasOneWithSingleDeposit) {
    <app-action-card
      [title]="'actions.extrapension.hasOne.title' | fms"
      [icon]="'actions.extrapension.hasOne.icon' | fms"
      [content]="'actions.extrapension.hasOne.contents' | fms: { args: fmsArgs$ | async }"
      [outlinedPrimaryButton]="false"
      [primaryButton]="'actions.extrapension.hasOne.primaryButton' | fms"
      [secondaryButton]="'actions.extrapension.hasOne.secondaryButton' | fms"
      (primaryButtonClick)="changeOrAddReccuringSavings()"
      (secondaryButtonClick)="addLumpSum()"
      [action]="action"
    >
    </app-action-card>
  }
  @case (State.HasOneWithRecurringDeposit) {
    <app-action-card
      [title]="'actions.extrapension.hasOneRecurring.title' | fms"
      [icon]="'actions.extrapension.hasOneRecurring.icon' | fms"
      [content]="'actions.extrapension.hasOneRecurring.contents' | fms: { args: fmsArgs$ | async }"
      [outlinedPrimaryButton]="false"
      [primaryButton]="'actions.extrapension.hasOneRecurring.primaryButton' | fms"
      [secondaryButton]="'actions.extrapension.hasOneRecurring.secondaryButton' | fms"
      (primaryButtonClick)="changeOrAddReccuringSavings()"
      (secondaryButtonClick)="addLumpSum()"
      [action]="action"
    >
    </app-action-card>
  }
}
