import { Injectable } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { isEmpty } from "lodash-es";
import { combineLatest, Observable, of, shareReplay } from "rxjs";
import { catchError, combineLatestWith, map, take } from "rxjs/operators";
import { OnboardingSalaryStepComponent } from "src/app/modules/shared/components/onboarding/onboarding-salary-step/onboarding-salary-step.component";
import { Step } from "src/app/modules/shared/components/steppable-modal/dynamic-step-navigation.service";
import {
  SteppableModalComponent,
  SteppableModalOptions,
} from "src/app/modules/shared/components/steppable-modal/steppable-modal.component";
import { ConsentService } from "src/app/services/consent.service";
import { applyArrayTo } from "src/app/utils/applyArrayTo";
import { getIsNotNullable, Nullable } from "src/app/utils/utils";
import { OnboardingNorskPensjonAndStorageStepComponent } from "../modules/shared/components/onboarding/onboarding-norsk-pensjon-and-storage-step/onboarding-norsk-pensjon-and-storage-step.component";
import { OnboardingPublicPensionStepComponent } from "../modules/shared/components/onboarding/onboarding-public-pension-step/onboarding-public-pension-step.component";
import { PublicPensionQueriesService } from "./api/public-pension-queries.service";
import { CustomerService } from "./customer.service";
import { IncomeService } from "./income/income.service";

export interface OnboardingOpenOptions {
  onComplete?: (metadata: any) => void;
}

export enum OnboardingStepKey {
  PublicPension = "PublicPension",
  Salary = "Salary",
  NorskPensjonAndStorageConsent = "NorskPensjonAndStorageConsent",
}

enum ConsentKey {
  DataStorage = "dataStorage",
  NorskPensjon = "norskPensjon",
}

interface OnboardingStepMetadata {
  name: string;
  consent?: ConsentKey;
}

interface OnboardingStep extends Step {
  key: OnboardingStepKey;
  metadata: OnboardingStepMetadata;
}

@Injectable({
  providedIn: "root",
})
export class OnboardingService {
  private readonly steps: OnboardingStep[] = [];

  private readonly shouldShowPublicPensionStep$: Observable<boolean>;
  private readonly hasSalary$ = this.hasSalary().pipe(shareReplay());

  constructor(
    private readonly consentService: ConsentService,
    private readonly dialog: MatDialog,
    private readonly incomeService: IncomeService,
    private readonly customerService: CustomerService,
    private readonly publicPensionQueriesService: PublicPensionQueriesService,
  ) {
    this.steps = [
      {
        key: OnboardingStepKey.PublicPension,
        component: OnboardingPublicPensionStepComponent,
        metadata: { name: "Onboarding public pension step" },
      },
      {
        key: OnboardingStepKey.Salary,
        component: OnboardingSalaryStepComponent,
        metadata: { name: "Onboarding salary step" },
      },
      {
        key: OnboardingStepKey.NorskPensjonAndStorageConsent,
        component: OnboardingNorskPensjonAndStorageStepComponent,
        metadata: {
          name: "Onboarding norsk pensjon consent step",
        },
      },
    ];

    this.shouldShowPublicPensionStep$ = combineLatest([
      this.publicPensionQueriesService.isAnyPublicPensionContractActive().pipe(catchError(() => of(false))),
      this.customerService.navConsent$,
    ]).pipe(map(getShouldShowPublicPensionStep));
  }

  public open(options?: Partial<OnboardingOpenOptions>, stepKeys?: OnboardingStepKey[]): void {
    const mergedOptions: Required<OnboardingOpenOptions> = {
      onComplete: () => {
        // noop
      },
      ...options,
    };

    const steps$: Observable<SteppableModalOptions> = this.getStepsFromKeysOrDefaultSteps(stepKeys).pipe(
      map((steps) => ({
        steps,
        onComplete: (metadata): void => {
          mergedOptions.onComplete(metadata);
        },
      })),
    );

    steps$.pipe(take(1)).subscribe((steps) => {
      if (steps.steps.length > 0) {
        this.dialog.open(SteppableModalComponent, {
          data: steps,
          autoFocus: false,
          panelClass: "app-steppable-modal",
        });
      }
    });
  }

  public shouldOpen(): Observable<boolean> {
    return this.getConsentForKey(ConsentKey.DataStorage).pipe(
      combineLatestWith(
        this.getConsentForKey(ConsentKey.NorskPensjon),
        this.hasSalary$,
        this.shouldShowPublicPensionStep$,
      ),
      map(applyArrayTo(getShouldOpen)),
    );
  }

  public isMissingConsensts(): Observable<boolean> {
    return combineLatest([
      this.getConsentForKey(ConsentKey.DataStorage),
      this.getConsentForKey(ConsentKey.NorskPensjon),
    ]).pipe(
      map((consents: [Nullable<boolean>, Nullable<boolean>]) => {
        return this.hasUnansweredConsents(consents);
      }),
    );
  }

  /**
   * Check if any of the elements in the array are not booleans, meaning unanswered consents
   * @param consents
   */
  public hasUnansweredConsents(consents: [Nullable<boolean>, Nullable<boolean>]): boolean {
    return !consents.every((consent: Nullable<boolean>) => typeof consent === "boolean");
  }

  /**
   * The order of the steps array directs the order in which
   * steps are displayed in the onboarding modal.
   */
  private getDefaultSteps(): Observable<OnboardingStep[]> {
    return combineLatest([
      this.hasSalary$,
      this.shouldShowPublicPensionStep$,
      this.getConsentForKey(ConsentKey.DataStorage),
      this.getConsentForKey(ConsentKey.NorskPensjon),
    ]).pipe(
      map(([hasSalary, shouldShowPublicPensionStep, hasDataStorageConsent, hasNorskpensjonConsent]) =>
        [OnboardingStepKey.PublicPension, OnboardingStepKey.Salary, OnboardingStepKey.NorskPensjonAndStorageConsent]
          .filter(
            isOnboardingKeyIncludedByCondition(
              hasSalary,
              shouldShowPublicPensionStep,
              hasDataStorageConsent,
              hasNorskpensjonConsent,
            ),
          )
          .map((key) => this.getStep(key))
          .filter(getIsNotNullable)
          .map((step) => ({
            ...step,
          })),
      ),
    );
  }

  private getStep(key: OnboardingStepKey): OnboardingStep | undefined {
    return this.steps.find((step) => step.key === key);
  }

  private getConsentForKey(key: ConsentKey | undefined): Observable<Nullable<boolean>> {
    switch (key) {
      case ConsentKey.DataStorage:
        return this.consentService.customerSuppliedDataConsent$;
      case ConsentKey.NorskPensjon:
        return this.consentService.hasNorskPensjonConsent$;
      default:
        return of(false);
    }
  }

  private hasSalary(): Observable<boolean> {
    return this.incomeService.annualGrossIncome$.pipe(map(getIsNotNullable));
  }

  private getStepsFromKeysOrDefaultSteps(stepKeys: OnboardingStepKey[] | undefined): Observable<OnboardingStep[]> {
    return getIsNotNullable(stepKeys) && !isEmpty(stepKeys)
      ? of(stepKeys.map((key) => this.getStep(key)).filter(getIsNotNullable))
      : this.getDefaultSteps();
  }
}

function getShouldOpen(
  dataStorageConsent: Nullable<boolean>,
  norskPensjonConsent: Nullable<boolean>,
  hasSalary: boolean,
  shouldShowPublicPension: boolean,
): boolean {
  return !dataStorageConsent || !norskPensjonConsent || !hasSalary || shouldShowPublicPension;
}

function isOnboardingKeyIncludedByCondition(
  hasSalary: boolean,
  shouldShowPublicPension: boolean,
  dataStorageConsent: Nullable<boolean>,
  norskPensjonConsent: Nullable<boolean>,
): (key: OnboardingStepKey) => boolean {
  return (key: OnboardingStepKey): boolean => {
    switch (key) {
      case OnboardingStepKey.PublicPension:
        return shouldShowPublicPension;
      case OnboardingStepKey.Salary:
        return !hasSalary;
      case OnboardingStepKey.NorskPensjonAndStorageConsent:
        return !dataStorageConsent || !norskPensjonConsent;
      default:
        return true;
    }
  };
}

function getShouldShowPublicPensionStep([hasActivePublic, navConsent]: [boolean, boolean | undefined]): boolean {
  return hasActivePublic && navConsent !== true;
}
