import { isPlainObject } from "lodash-es";
import { first, map, Observable } from "rxjs";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { select } from "src/app/utils/rxjs/select";
import { EngagementName, EngagementSimulationStatus } from "../pension.model";
import { GenericGraphEngagement } from "./generic-graph-engagement.model";
import { mapStatusMessageToSimulationStatus, PublicPensionEngagement } from "./public-pension-engagement.model";
import { getPayoutPlanDuration, PayoutDuration } from "./generic-engagement.model";

export class PublicSalaryEngagement extends GenericGraphEngagement<
  Graph.PublicPensionContract[],
  Graph.PublicPensionPrognosis,
  Graph.PublicPensionPrognosisInput
> {
  public constructor(private readonly source: PublicPensionEngagement) {
    super(source.contract, source.prognosis);
  }

  public isActive(): boolean {
    return false;
  }

  public getContracts(): Graph.PublicPensionContract[] {
    return [];
  }

  public getIdentifier(): string {
    return this.getName().name;
  }

  /** @deprecated Use getNameAsync instead */
  public getName(): EngagementName {
    return select(this.getNameAsync());
  }

  public getNameAsync(): Observable<EngagementName> {
    const fmsService = this.getFmsService();
    return fmsService.translateAsync<string>("chartService.annualGrossIncomeSeries.name").pipe(
      first(),
      map((name) => ({
        name,
      })),
    );
  }

  public getPayoutPlan(): Graph.PayoutPlan[] {
    const startAge = this.source.getPayoutPlan().at(0)?.age;
    const startIndex = this.prognosis?.paymentPlanSalary?.findIndex((el) => el.age === startAge);

    if (startIndex === -1) {
      return [];
    }

    return (<Graph.PayoutPlan[]>[])
      .concat(this.prognosis?.paymentPlanSalary ?? [])
      .slice(startIndex)
      .filter(isPlainObject);
  }

  public getPayoutDuration(): PayoutDuration {
    return getPayoutPlanDuration(this.getPayoutPlan());
  }

  public hasFolketrygdPrognosis(): boolean {
    return false;
  }

  public getPayoutFromAge(): number | undefined {
    return this.getPayoutPlan().at(0)?.age || undefined;
  }

  public getPayoutToAge(): number | undefined {
    return this.getPayoutPlan().at(-1)?.age || undefined;
  }

  public getSimulationStatus(): EngagementSimulationStatus[] {
    const DEFAULT_SIMULATION_STATUSES = [{ severity: Graph.SimulationSeverity.Ok }];
    return this.prognosis?.statusMessages?.map(mapStatusMessageToSimulationStatus) ?? DEFAULT_SIMULATION_STATUSES;
  }

  public getFirstYearPayout(): number {
    const firstYear = this.getPayoutPlan().at(0);
    return firstYear?.amount ?? 0;
  }

  public isLifelongPayout(): boolean {
    return false;
  }

  public isSavingsEngagement(): boolean {
    return false;
  }

  public hasChangeableProfile(): boolean {
    return false;
  }
}
