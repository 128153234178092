@if (alert$ | async; as alert) {
  <button
    class="popover-inline-alert-component"
    type="button"
    #popover
    (click)="click()"
    (mouseenter)="hideTooltip()"
    [matTooltip]="alert.tooltip"
    matTooltipPosition="above"
    matTooltipClass="storeblocks-styling"
    [matTooltipDisabled]="true"
  >
    <stb-inline-alert [label]="alert.fmsText | fms" [type]="alert.type" [width]="'fluid'"></stb-inline-alert>
  </button>
}
