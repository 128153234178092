@if (isCommon()) {
  <app-engagement-details-common
    [engagement]="engagement"
    [hasError]="hasError"
    [showSimulationParameters]="showSimulationParameters"
    [showPensionPlanEntry]="showPensionPlanEntry"
    [showDescription]="showDescription"
    [responsive]="responsive"
  ></app-engagement-details-common>
}

@if (isAfp(engagement)) {
  <app-engagement-details-afp
    [engagement]="engagement"
    [showSimulationParameters]="showSimulationParameters"
    [showDescription]="showDescription"
    [responsive]="responsive"
  ></app-engagement-details-afp>
}

@if (isExternalSavings(engagement)) {
  <app-engagement-details-external
    [engagement]="engagement"
    [hasError]="hasError"
    [showSimulationParameters]="showSimulationParameters"
    [responsive]="responsive"
  ></app-engagement-details-external>
}

@if (isItpUnfunded(engagement)) {
  <app-engagement-details-itp-unfunded
    [engagement]="engagement"
    [hasError]="hasError"
    [showSimulationParameters]="showSimulationParameters"
    [responsive]="responsive"
  ></app-engagement-details-itp-unfunded>
}

@if (isOtherPension(engagement)) {
  <app-engagement-details-other
    [engagement]="engagement"
    [hasError]="hasError"
    [showSimulationParameters]="showSimulationParameters"
    [showDescription]="showDescription"
    [responsive]="responsive"
  ></app-engagement-details-other>
}

@if (isPublicPension(engagement)) {
  <app-engagement-details-public-pension
    [engagement]="engagement"
    [hasError]="hasError"
    [showSimulationParameters]="showSimulationParameters"
    [responsive]="responsive"
  ></app-engagement-details-public-pension>
}
