<button mat-dialog-close data-trackid="combinedNewEngagementDialog-closeButton">
  <i>close</i>
</button>

<h3 mat-dialog-title>
  {{ "payoutSimulationAlternatives.dialog.title" | fms }}
</h3>

<mat-dialog-content>
  @if (investmentRadios$ | async; as investmentRadios) {
    <div class="radio-title-wrapper">
      <span class="text-short-bold">{{ "payoutSimulationAlternatives.dialog.investmentRadio.title" | fms }}</span>
      <app-tip-icon [tooltipText]="'payoutSimulationAlternatives.dialog.investmentRadio.popover' | fms"></app-tip-icon>
    </div>
    <stb-radio-buttons
      label="test label"
      [horizontal]="false"
      [group]="investmentRadios"
      (valueChanged)="setInvestmentChoice($event.value)"
    >
    </stb-radio-buttons>
  }

  @if (compressionRadios$ | async; as compressionRadios) {
    <div class="radio-title-wrapper">
      <span class="text-short-bold">{{ "payoutSimulationAlternatives.dialog.compressionLimitRadio.title" | fms }}</span>
      <app-tip-icon
        [tooltipText]="'payoutSimulationAlternatives.dialog.compressionLimitRadio.popover' | fms"
      ></app-tip-icon>
    </div>
    <stb-radio-buttons
      [horizontal]="false"
      [group]="compressionRadios"
      (valueChanged)="setCompressionChoice($event.value)"
    >
    </stb-radio-buttons>
  }

  @if (userFriendlyTextResultOfChanges$ | async; as label) {
    <div class="result">
      <stb-inline-alert type="success" [label]="label" width="fluid"></stb-inline-alert>
    </div>
  }
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-dialog-close stbButton="contained" type="button" data-trackid="payoutSimulationAlternatives.dialog.ok">
    {{ "common.ok" | fms }}
  </button>
</mat-dialog-actions>
