@if (showCtaCard$ | async) {
  <div class="add-offentlig-card full-page-width-limiter">
    <div class="panel-header">
      <h4>{{ title$ | async }}</h4>
      <button
        stbButton="text"
        class="close-button"
        data-trackid="offentligTjenestepensjonCtaCard-optOutButton"
        [setIconAfter]="true"
        iconType="close"
        (click)="setOptOutTrue()"
      >
        {{ "ctaCard.offentligTjeneste.optOutButtonText" | fms }}
      </button>
    </div>
    <div class="panel-body">
      <p>{{ "ctaCard.offentligTjeneste.description" | fms }}</p>
      <button
        stbButton="text"
        data-trackid="offentligTjenestepensjonCtaCard-addButton"
        [setIconBefore]="true"
        iconType="add"
        (click)="openDialog()"
      >
        {{ "ctaCard.offentligTjeneste.buttonText" | fms }}
      </button>
    </div>
  </div>
}
