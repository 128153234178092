@if (hasBalance$ | async) {
  <div>
    <div class="hint">
      {{ "engagement.label.balance" | fms }}
    </div>
    <div class="paragraph-long-bold">
      {{ balance$ | async | currencyFormatterPipe: "end" }}
    </div>
  </div>
}

@if (hasTotalReturn$ | async) {
  <div>
    <div class="hint">
      {{ totalReturnLabel$ | async }}
    </div>
    <div class="paragraph-long-bold">
      {{ formattedTotalReturn$ | async }}
    </div>
  </div>
}
