@if (visible$ | async) {
  <app-expansion-panel-accordion [(expanded)]="open" [showFooter]="true">
    <div class="title-wrapper" title>
      <div class="title-icon" [ngClass]="type">
        <i>{{ getIcon() }}</i>
      </div>
      <h2>{{ getTitle() }}</h2>
    </div>
    <ng-content></ng-content>
    <span footerTitle>{{ footerTitle }}</span>
    <span [innerHTML]="footerHtml" footer></span>
  </app-expansion-panel-accordion>
}
