<ng-container *appLet="stepper$ | async as stepper" [formGroup]="externalEngagementForm">
  <button mat-dialog-close>
    <i>close</i>
  </button>
  <h3 mat-dialog-title>{{ engagementSteps[stepper].windowTitle }}</h3>

  <mat-dialog-content>
    @if (stepper === 0) {
      <ng-container formGroupName="firstGroup">
        <stb-radio-buttons
          [legend]="'externalEngagement.form.type.legend' | fms"
          [group]="typeRadioButtons$ | async"
          [message]="'externalEngagement.form.type.validation' | fms"
          [warning]="type.touched && type.invalid"
          (valueChanged)="setType($event)"
        ></stb-radio-buttons>
      </ng-container>
    } @else if (stepper === 1) {
      <ng-container formGroupName="secondGroup">
        <stb-radio-buttons
          [legend]="'externalEngagement.form.ongoing.legend' | fms"
          [group]="savingIntervalRadioButtons$ | async"
          [message]="'externalEngagement.form.ongoing.validation' | fms"
          [warning]="savingInterval.touched && savingInterval.invalid"
          (valueChanged)="setSavingInterval($event.value)"
        ></stb-radio-buttons>

        @if (savingInterval.value !== null && savingInterval.value !== undefined) {
          <stb-form
            size="large"
            [label]="
              (type.value === ExternalSavingsEngagementType.Bank
                ? 'externalEngagement.form.balance.bankLabel'
                : 'externalEngagement.form.balance.fondLabel'
              ) | fms
            "
            [error]="balance.touched && (balance.hasError('required') || balance.hasError('pattern'))"
            [errorMessage]="
              (deposit.hasError('required')
                ? 'externalEngagement.form.balance.validation.required'
                : 'externalEngagement.form.balance.validation.zero'
              ) | fms
            "
          >
            <input appCurrencyMask data-hj-suppress formControlName="balance" id="balance" type="tel" />
          </stb-form>

          @if (savingInterval.value === SavingInterval.Ongoing) {
            <stb-form
              size="large"
              [label]="'externalEngagement.form.deposit.label' | fms"
              [error]="deposit.touched && (deposit.hasError('required') || deposit.hasError('pattern'))"
              [hint]="'externalEngagement.form.deposit.placeholder' | fms"
              [errorMessage]="
                (deposit.hasError('pattern')
                  ? 'externalEngagement.form.deposit.validation.zero'
                  : 'externalEngagement.form.deposit.validation.required'
                ) | fms
              "
            >
              <input appCurrencyMask data-hj-suppress formControlName="deposit" id="deposit" type="tel" />
            </stb-form>
          }
        }
      </ng-container>
    } @else if (stepper === 2) {
      <ng-container formGroupName="thirdGroup">
        <stb-form
          size="large"
          [label]="'externalEngagement.form.title.label' | fms"
          [error]="title.touched && title.hasError('required')"
          [errorMessage]="'externalEngagement.form.title.validation' | fms"
        >
          <input data-hj-suppress formControlName="title" id="title" type="text" />
        </stb-form>

        <stb-form
          size="large"
          [label]="'externalEngagement.form.payer.label' | fms"
          [error]="payer.touched && payer.hasError('required')"
          [errorMessage]="'externalEngagement.form.payer.validation' | fms"
        >
          <input
            data-hj-suppress
            formControlName="payer"
            id="payer"
            type="text"
            [placeholder]="'externalEngagement.form.payer.placeholder' | fms"
          />
        </stb-form>
      </ng-container>
    }
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      stbButton="outlined"
      type="button"
      (click)="onBackButton()"
      [attr.data-trackid]="'externalEngagementDialog-backButton' + stepper"
    >
      {{ engagementSteps[stepper].backButtonText }}
    </button>
    <button
      stbButton="contained"
      type="button"
      (click)="onNextButton()"
      [attr.data-trackid]="'externalEngagementDialog-nextButton' + stepper"
      [enableSpinner]="isSubmitting$ | async"
    >
      @if (hasInjectedEngagement() && this.isFinalStep()) {
        {{ "addEngagement.saveAfterEditButton.text" | fms }}
      } @else {
        {{ engagementSteps[stepper].nextButtonText }}
      }
    </button>
  </mat-dialog-actions>
</ng-container>
