<app-expandable-error-alert
  [visible$]="hasAlerts$"
  [title]="'expandableAlerts.title' | fms"
  [footerTitle]="'expandableAlerts.footerTitle' | fms"
  [footerHtml]="'expandableAlerts.footerHtml' | fms"
>
  <p>{{ "expandableAlerts.message.fetchingError" | fms }}</p>
  <p>{{ "expandableAlerts.message.tryAgain" | fms }}</p>

  <mat-expansion-panel class="errors mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ "expandableAlerts.expansion.title" | fms }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    @for (alert of alerts$ | async; track alert.text.title) {
      <div class="error">
        <strong>{{ alert.text.title | fms }}</strong
        ><br />
        {{ alert.text.message | fms }}
      </div>
    }
  </mat-expansion-panel>

  <button stbButton="contained" (click)="refresh()" data-trackid="button-refresh">
    {{ "expandableAlerts.refreshButton" | fms }}
  </button>
</app-expandable-error-alert>
