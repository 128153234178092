import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { RouteKey, routes } from "src/app/modules/smart-account/routes";
import { AnyEngagement } from "src/app/services/engagements.service";
import { FmsService } from "src/app/services/fms.service";
import { SmartAccountService, SmartAccountState } from "src/app/services/smart-account.service";
import { getIsSimulationStatusOk } from "src/app/utils/engagement.utils";
import { composePaths } from "src/app/utils/router.utils";
import { ContractActionBuilder, ContractActionType, InternalLink } from "../contract-actions.types";
import { getIsExternalSavingsBankEngagement } from "../contract-actions.utils";

@Injectable()
export class MoveSavingsToSmartAccountActionBuilder implements ContractActionBuilder {
  constructor(
    private readonly fmsService: FmsService,
    private readonly smartAccountService: SmartAccountService,
  ) {}

  public predicate(engagement: AnyEngagement): Observable<boolean> {
    const validSmartAccountStatesForAction = [
      SmartAccountState.Qualified,
      SmartAccountState.HasOneAccount,
      SmartAccountState.HasMultipleAccounts,
    ];
    return this.smartAccountService.smartAccountState$.pipe(
      map(
        (smartAccountState) =>
          getIsSimulationStatusOk(engagement) &&
          getIsExternalSavingsBankEngagement(engagement) &&
          validSmartAccountStatesForAction.includes(smartAccountState),
      ),
    );
  }

  public build(): InternalLink {
    return {
      type: ContractActionType.Internal,
      linkLabel: this.getLinkLabel(),
      linkRouter: this.getLinkUrl(),
      linkTrackId: this.getLinkTrackId(),
    };
  }

  private getLinkLabel(): string {
    return this.fmsService.instant("contractActions.moveSavingsToSmartAccountAction.linkLabel");
  }

  private getLinkUrl(): string {
    return composePaths(routes, RouteKey.Root);
  }

  private getLinkTrackId(): string {
    return "move-banksaving-to-smartkonto";
  }
}
