@if (loading) {
  <app-engagement-loading-skeleton [isContract]="true" />
} @else {
  <div class="contract-list" *appLet="isMobile$ | async as isMobile">
    <div
      class="engagement-panel"
      *appLet="isExpanded$ | async as isExpanded"
      [class.expanded]="isExpanded"
      [class.defaultExpanded]="defaultExpanded"
      [class.active]="contract.isActive()"
    >
      <mat-expansion-panel
        (expandedChange)="expandedChange($event)"
        [expanded]="isExpanded"
        [@.disabled]="disableAnimation"
        disabled
        hideToggle
      >
        <mat-expansion-panel-header class="mat-expansion-panel-header">
          <div class="header-title">
            <div class="header-title-details">
              <h4>
                {{ getHeader().title | titlecaseFirstWord }}
              </h4>
              @if (showDescriptionTooltip && (description$ | async); as description) {
                <app-tip-icon [tooltipText]="description" class="hide-only-on-print"></app-tip-icon>
              }
              @if (contract.isActive()) {
                <app-chip [text]="'engagement.active' | fms"></app-chip>
              }
            </div>
            <app-supplier-text-or-logo class="tablet-or-larger" [engagement]="contract"></app-supplier-text-or-logo>
          </div>
          @if (!defaultExpanded) {
            <div class="header-secondary">
              @if (getHeader().subtitle?.length) {
                @if (contract.isActive() && (hasEmployerAnnualSavingsAmount$ | async)) {
                  @if (isMobile) {
                    <div class="hint" data-hj-suppress>
                      {{ getSupplier() }}
                    </div>
                  }
                  <div
                    class="paragraph-long active-subtitle"
                    data-hj-suppress
                    [innerHtml]="
                      'contractsComponent.activeSubtitle'
                        | fms
                          : {
                              args: {
                                payer: getPayer(),
                                yearlySavings: getFormattedEmployerAnnualSavingsAmount$ | async,
                              },
                            }
                    "
                  ></div>
                } @else {
                  @if (isMobile && getHeader().subtitle) {
                    <div class="hint" data-hj-suppress>
                      {{ getHeader().subtitle }}
                    </div>
                  }
                  @if (!isMobile && getPayer()) {
                    <div class="hint" data-hj-suppress>
                      {{ getPayer() }}
                    </div>
                  }
                }
              }
              @if (hasMovedEpkOut) {
                @if (!isExpanded) {
                  <stb-inline-alert
                    [label]="'engagement.alert.hasMovedEpkOutFromSTB.title' | fms"
                    width="fluid"
                    type="info"
                    rebrand="false"
                  >
                  </stb-inline-alert>
                }
              } @else {
                <app-contract-balance-and-total-return [engagement]="contract"></app-contract-balance-and-total-return>
              }
              <div class="toggle-button">
                @if (!defaultExpanded) {
                  <app-expand-toggle-button
                    [isExpanded]="isExpanded"
                    (toggleClick)="expandedChange(!isExpanded)"
                  ></app-expand-toggle-button>
                }
              </div>
            </div>
          }
        </mat-expansion-panel-header>
        <app-contract-actions-container [engagement]="contract"></app-contract-actions-container>

        @if (isExpanded) {
          @if (hasMovedEpkOut) {
            <stb-alert
              [heading]="'engagement.alert.hasMovedEpkOutFromSTB.title' | fms"
              [text]="'engagement.alert.hasMovedEpkOutFromSTB.description' | fms"
              type="info"
              width="fluid"
              class="stb-mt2"
            ></stb-alert>
          }
          @if (showMarketValueChart$ | async) {
            <div class="stb-mt6 stb-mb4">
              <app-market-value-chart [marketValueChartData$]="marketValueChartData$"></app-market-value-chart>
            </div>
          }
          @if (annualReturn$ | async; as annualReturn) {
            <app-contract-details-annual-return-pct
              [annualReturn]="annualReturn"
            ></app-contract-details-annual-return-pct>
          }
          <app-engagement-details
            [engagement]="contract"
            [showSimulationParameters]="false"
            [showPensionPlanEntry]="contract.hasStipultatedBaseRatePayout()"
            [showDescription]="!showDescriptionTooltip"
            [hasError]="false"
          >
          </app-engagement-details>
          <app-link-to-details-page [engagement]="contract"></app-link-to-details-page>
        }
      </mat-expansion-panel>
      <app-engagement-footer [engagement]="contract" tabindex="-1"></app-engagement-footer>
    </div>
  </div>
}
