import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ContractAction, ContractActionType } from "../../../services/contract-actions/contract-actions.types";

@Component({
  selector: "app-contract-actions-bar",
  templateUrl: "./contract-actions-bar.component.html",
  styleUrls: ["./contract-actions-bar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractActionsBarComponent {
  @Input()
  public readonly title: string = "";
  @Input()
  public readonly actions: ContractAction[] = [];
  @Input()
  public readonly isActive?: boolean;
  public contractActionType = ContractActionType;
}
