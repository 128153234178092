<ng-container *appLet="stepper$ | async as stepper">
  <button mat-dialog-close>
    <i>close</i>
  </button>
  <h3 mat-dialog-title>{{ engagementSteps[stepper].windowTitle }}</h3>

  <mat-dialog-content>
    @if (stepper === 0) {
      <div class="colored-padded-container margin-bottom">
        <label>{{ "afpEngagement.label" | fms | uppercase }}</label>
        @if (hasAfpValue$ | async) {
          <app-labeled-number [value]="afpValue$ | async" suffix="kr/år"></app-labeled-number>
        } @else {
          <app-text-skeleton></app-text-skeleton>
        }
      </div>
      <ul>
        <li>
          <i class="info-icon">information-filled</i>
          <p>{{ "afpEngagement.firstInfo.text" | fms }}</p>
        </li>
        <li>
          <i class="warning-icon">warning-filled</i>
          <p [innerHTML]="'afpEngagement.secondInfo.text' | fms"></p>
        </li>
        @if (hasOffentligTjenestepensjonFlag$) {
          <li>
            <div class="icon-placeholder"></div>
            <p [innerHTML]="'afpEngagement.publicPensionInfo.text' | fms"></p>
          </li>
        }
      </ul>
    }
  </mat-dialog-content>

  <mat-dialog-actions>
    <button
      stbButton="outlined"
      type="button"
      (click)="onBackButton()"
      [attr.data-trackid]="'afpEngagementDialog-backButton' + stepper"
    >
      {{ engagementSteps[stepper].backButtonText }}
    </button>
    <button
      stbButton="contained"
      type="button"
      (click)="onNextButton()"
      [attr.data-trackid]="'afpEngagementDialog-nextButton' + stepper"
    >
      {{ engagementSteps[stepper].nextButtonText }}
    </button>
  </mat-dialog-actions>
</ng-container>
