import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { PublicPensionQueriesService } from "src/app/services/api/public-pension-queries.service";
import { Contract as PublicPensionContract } from "src/app/models/engagements/public-pension-engagement.model";

@Injectable({
  providedIn: "root",
})
export class HasPublicPensionInStorebrandService {
  public readonly isPublicContext$: Observable<boolean>;

  constructor(private readonly publicPensionQueriesService: PublicPensionQueriesService) {
    this.isPublicContext$ = this.publicPensionQueriesService
      .getPublicPensionContracts()
      .pipe(map(hasPublicPensionContracts));
  }
}

function hasPublicPensionContracts(contracts: PublicPensionContract[] | null): boolean {
  return contracts !== null && contracts.length > 0;
}
