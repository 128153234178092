@if (validateIsTemplateIterable(promoBoxes)) {
  <div class="promo">
    @for (promoBox of promoBoxes; track promoBox.title) {
      <div>
        <app-promo-box
          [promoPicture]="promoBox.picture"
          [promoTitle]="promoBox.title"
          [promoContent]="promoBox.content"
        ></app-promo-box>
      </div>
    }
  </div>
}
