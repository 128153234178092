@if (description$ | async; as description) {
  <div [ngClass]="{ 'wrapper stb-typography': showDescription || showPensionPlanEntry }">
    @if (showDescription) {
      <div class="paragraph-long-bold">
        {{ "contractTypeDescription.title" | fms: { args: { contractName: contractName | async } } }}
      </div>
    }
    @if (showDescription || showPensionPlanEntry) {
      <p>
        @if (showDescription) {
          <span [innerHtml]="description"></span>
        }
        @if (showPensionPlanEntry) {
          <span class="description-extended">
            {{ "ytelsespensjon.description.extended" | fms }}
          </span>
        }
      </p>
    }
    @if (showPensionPlanEntry) {
      <div class="description-action-container">
        <a class="anchor-stbButton-wrapper" [routerLink]="pensionPlanRoute" queryParamsHandling="merge" tabIndex="-1">
          <button role="link" stbButton="text" [setIconAfter]="true" iconType="arrow-right">
            {{ "ytelsespensjon.description.action.label" | fms }}
          </button>
        </a>
      </div>
    }
  </div>
}
