import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { AfpEngagement } from "src/app/models/engagements/norskpensjon/afp-engagement.model";
import { ExternalSavingsEngagement } from "src/app/models/engagements/external-savings-engagement.model";
import { OtherPensionEngagement } from "src/app/models/engagements/other-pension-engagement.model";
import { AnyEngagement } from "src/app/services/engagements.service";
import { FmsService } from "src/app/services/fms.service";
import { PensionPlanService } from "src/app/services/pension-plan.service";
import { ButtonLink, ContractActionBuilder, ContractActionType } from "../contract-actions.types";

@Injectable()
export class DeleteContractActionBuilder implements ContractActionBuilder {
  constructor(
    private readonly pensionPlanService: PensionPlanService,
    private readonly fmsService: FmsService,
  ) {}

  public predicate(engagement: AnyEngagement): boolean {
    return (
      engagement instanceof AfpEngagement ||
      engagement instanceof ExternalSavingsEngagement ||
      engagement instanceof OtherPensionEngagement
    );
  }

  public async build(engagement: AnyEngagement): Promise<ButtonLink> {
    const [linkLabel, linkTrackId] = await Promise.all([this.getLinkLabel(), this.getLinkTrackId()]);

    return {
      type: ContractActionType.Button,
      linkIcon: "delete",
      linkLabel,
      linkTrackId,
      onClick: (): void => {
        this.deleteContract(engagement);
      },
    };
  }

  private getLinkLabel(): Promise<string> {
    return firstValueFrom(this.fmsService.translateAsync<string>("contractActions.deleteContract.linkLabel"));
  }

  private getLinkTrackId(): Promise<string> {
    return Promise.resolve("contractAction-deleteContract");
  }

  private deleteContract(engagement: AnyEngagement): void {
    if (engagement instanceof AfpEngagement) {
      this.pensionPlanService.deleteAfpEngagement();
    } else if (engagement instanceof ExternalSavingsEngagement) {
      this.pensionPlanService.deleteExternalSavingsEngagement(engagement.contract);
    } else if (engagement instanceof OtherPensionEngagement) {
      this.pensionPlanService.deleteOtherPensionEngagement(engagement);
    }
  }
}
