import { Inject, Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { AnyEngagement } from "src/app/services/engagements.service";
import { FmsService } from "src/app/services/fms.service";
import { WINDOW } from "src/app/tokens";
import { getIsInPayoutPhase } from "src/app/utils/engagement.utils";
import { ButtonLink, ContractActionBuilder, ContractActionType } from "../contract-actions.types";

@Injectable()
export class LinkToPayoutsWebActionBuilder implements ContractActionBuilder {
  constructor(
    private readonly fmsService: FmsService,
    @Inject(WINDOW)
    private readonly window: Window,
  ) {}

  public predicate(engagement: AnyEngagement): boolean {
    return this.isNotAllowedInPayoutPhase(engagement);
  }

  public async build(_engagement: AnyEngagement): Promise<ButtonLink> {
    const [linkLabel, linkUrl, linkTrackId] = await Promise.all([
      this.getLinkLabel(),
      this.getLinkUrl(),
      this.getLinkTrackId(),
    ]);

    return {
      type: ContractActionType.Button,
      linkLabel,
      linkTrackId,
      onClick: (): void => {
        this.window.open(linkUrl, "_blank");
      },
    };
  }

  private getLinkLabel(): Promise<string> {
    return firstValueFrom(this.fmsService.translateAsync("contractActions.linkToPayoutsWeb.linkLabel"));
  }

  private getLinkUrl(): Promise<string> {
    return firstValueFrom(this.fmsService.translateAsync("contractActions.linkToPayoutsWeb.linkUrl"));
  }

  private getLinkTrackId(): Promise<string> {
    return Promise.resolve("navigate-to-payouts-web-contract-action");
  }

  private isNotAllowedInPayoutPhase(engagement: AnyEngagement): boolean {
    return getIsInPayoutPhase(engagement);
  }
}
