import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { Holding } from "src/app/modules/shared/services/fetch-holdings-and-allocation.service";
import { FmsService } from "src/app/services/fms.service";

export interface DialogData {
  holdings: Holding[];
  contractNumberForUrl?: string;
}

@Component({
  selector: "app-holdings-dialog",
  templateUrl: "./holdings-dialog.component.html",
  styleUrls: ["./holdings-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HoldingsDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogData,
    private readonly fmsService: FmsService,
  ) {}

  public changeInvestmentButtonClick(): void {
    const exitUrl = window.location.href;
    const contractNumber = this.data.contractNumberForUrl;

    window.open(
      this.fmsService.instant("agreement_details.changeSavingsProfileUrl", {
        args: { contractNumber, exitUrl },
      }),
      "_self",
    );
  }
}
