<app-action-dialog>
  <ng-container title>
    {{ "actions.withdrawalOrder.dialog.title" | fms }}
  </ng-container>

  @if (hasSortableEngagements$ | async) {
    <div class="content" [innerHTML]="'actions.withdrawalOrder.dialog.content' | fms"></div>
    <app-engagement-list-initiatives
      [headers]="[('actions.withdrawalOrder.dialog.headers.simulatedTotal' | fms)!]"
      [items]="items$ | async"
      [isNumberedList]="true"
      [itemsCutoff]="100"
    >
    </app-engagement-list-initiatives>
  } @else {
    <div [innerHTML]="'actions.withdrawalOrder.dialog.contentFail' | fms"></div>
  }

  <ng-container buttons>
    <button
      stbButton="contained"
      iconType="arrow-right"
      [setIconAfter]="true"
      data-trackid="actions-withdrawalOrderDialog"
      (click)="onPrimaryButtonClick()"
    >
      {{ "actions.withdrawalOrder.dialog.button" | fms }}
    </button>

    <button stbButton="outlined" mat-dialog-close data-trackid="actions-withdrawalorder-dialog-secondaryButton">
      {{ "actions.withdrawalOrder.dialog.secondaryButton" | fms }}
    </button>
  </ng-container>
</app-action-dialog>
