<div class="wrapper" [class.illustration]="illustration">
  <div class="advisor-container">
    <div class="hero-container">
      @if (!illustration) {
        <div class="quote-img">
          <img [src]="'quoteHero.circleQuoteLarge.svg' | fms" />
        </div>
      }

      <div class="name">
        <div class="name-text">{{ heroDetails.imageText }}</div>
        <div class="name-subline">{{ heroDetails.imageSubline }}</div>
      </div>
      <div class="hero">
        <img [src]="heroDetails.imageUrl" [alt]="heroDetails.imageAlt" />
      </div>
    </div>
  </div>

  <div class="quote-container">
    <div class="container-text">
      <h1>{{ heroDetails.title }}</h1>
    </div>
    @if (heroDetails.subline) {
      <div class="container-subline">
        {{ heroDetails.subline }}
      </div>
    }
  </div>
</div>
