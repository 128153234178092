import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Router } from "@angular/router";
import { Observable, ReplaySubject, filter, first, firstValueFrom, forkJoin, map, of, switchMap, take } from "rxjs";
import { EngagementBody } from "src/app/modules/pension-plan/components/engagement/engagement-body/engagement-body.component";
import { RouteKey as PensionPlanRouteKey, routes } from "src/app/modules/pension-plan/routes";
import {
  getContractNumber,
  getHasHoldings,
  getHoldings,
  getIsAnbefaltPensjon,
  getYearlyPremiumAmount,
} from "src/app/modules/shared/components/engagement-details/engagement-details-common/engagement-details-common.utils";
import { ContractDetailsService } from "src/app/modules/shared/services/contract-details.service";
import { FetchHoldingsAndAllocationService } from "src/app/modules/shared/services/fetch-holdings-and-allocation.service";
import { EngagementService } from "src/app/services/engagement.service";
import { AnyEngagement } from "src/app/services/engagements.service";
import {
  AnySavingsAndPensionEngagement,
  SavingsAndPensionService,
} from "src/app/services/prognoses-services/savings-and-pension.service";
import { isBankEngagement } from "src/app/utils/engagement.typeguards";
import { composePaths } from "src/app/utils/router.utils";
import { memoizeObject$ } from "src/app/utils/rxjs/select";
import { Nullable, getIsNotNullable } from "src/app/utils/utils";
import { HoldingsDialogComponent } from "../../holdings-dialog/holdings-dialog.component";

@Component({
  selector: "app-engagement-details-common",
  templateUrl: "./engagement-details-common.component.html",
  styleUrls: ["../engagement-details-shared.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngagementDetailsCommonComponent implements EngagementBody {
  @Input()
  public hasError!: boolean;
  @Input()
  public showSimulationParameters!: boolean;
  @Input()
  public responsive = true;
  @Input()
  public showPensionPlanEntry = false;
  @Input()
  public showDescription = true;

  public readonly engagement$: ReplaySubject<AnyEngagement> = new ReplaySubject<AnyEngagement>();

  public readonly equalGraphEngagement$ = memoizeObject$(this.findEqualGraphEngagement(this.engagement$));

  public readonly isBank$ = this.engagement$.pipe(map(isBankEngagement));

  public readonly yearlyPremiumAmount$ = getYearlyPremiumAmount(this.engagement$);

  public readonly totalPayout$ = this.getTotalPayout(this.engagement$);

  public readonly duration$ = this.getDuration(this.engagement$);

  public readonly contractNumber$ = getContractNumber(this.engagement$);

  public readonly showPayoutSimulationAlternatives$ = this.showPayoutSimulationAlternatives();

  private readonly contractDetails$ = this.equalGraphEngagement$.pipe(
    switchMap((engagement) => this.contractDetailsService.fetchContractDetails(engagement)),
  );

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public readonly engagmentHoldingsAndSavingsProfile$ = forkJoin([
    this.equalGraphEngagement$.pipe(first()),
    this.contractDetails$.pipe(first()), // hydrate cache
  ]).pipe(
    switchMap(([engagement]) => this.fetchHoldingsAndAllocationService.fetchHoldingsAndSavingsProfile(engagement)),
  );
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public readonly holdings$ = getHoldings(this.engagmentHoldingsAndSavingsProfile$);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public readonly isAnbefaltPensjon$ = getIsAnbefaltPensjon(this.engagmentHoldingsAndSavingsProfile$);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public readonly hasHoldings$ = getHasHoldings(this.holdings$);

  constructor(
    public readonly engagementService: EngagementService,
    private readonly savingsAndPensionService: SavingsAndPensionService,
    private readonly fetchHoldingsAndAllocationService: FetchHoldingsAndAllocationService,
    private readonly contractDetailsService: ContractDetailsService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
  ) {}

  @Input()
  public set engagement(anyEngagement: AnyEngagement) {
    if (anyEngagement) {
      this.engagement$.next(anyEngagement);
    }
  }

  public async openDialogWithFunds(): Promise<void> {
    const engagement = await firstValueFrom(this.equalGraphEngagement$);
    const holdings = await firstValueFrom(this.holdings$);
    const contractNumberForUrl = engagement.getContractNumber();

    this.dialog.open(HoldingsDialogComponent, {
      autoFocus: false,
      restoreFocus: false,
      panelClass: "large-dialog-padding",
      data: {
        contractNumberForUrl,
        holdings,
      },
    });
  }

  private getTotalPayout(engagement$: Observable<AnyEngagement>): Observable<Nullable<number>> {
    return engagement$.pipe(map((engagement) => engagement.getTotalPayout()));
  }

  private getDuration(engagement$: Observable<AnyEngagement>): Observable<string> {
    return engagement$.pipe(map((engagement) => this.engagementService.getHumanReadableDuration(engagement)));
  }

  private findEqualGraphEngagement(engagement$: Observable<AnyEngagement>): Observable<AnySavingsAndPensionEngagement> {
    return engagement$.pipe(
      switchMap((anyEngagement) =>
        this.savingsAndPensionService.engagements$.pipe(
          take(1),
          map((engagements) => engagements.find((graphEngagement) => graphEngagement.isEqualTo(anyEngagement))),
          filter(getIsNotNullable),
        ),
      ),
    );
  }

  private showPayoutSimulationAlternatives(): Observable<boolean> {
    const isPensionPlanRoute = this.router.url.includes(composePaths(routes, PensionPlanRouteKey.Root));
    return of(isPensionPlanRoute);
  }
}
