<div class="contracts" *appLet="readyToRender$ | async as readyToRender">
  <ng-container
    *appLet="'engagementList.savings.title' | fms as title"
    [ngTemplateOutlet]="ContractList"
    [ngTemplateOutletContext]="{
      headerText: getHeaderText({ title }),
      contractItems: savingsContractItems$ | async,
      skeletons: 1,
      readyToRender: readyToRender,
      emptyContractItemsOutlet: emptySavings
    }"
  ></ng-container>

  <ng-template #emptySavings>
    <a [routerLink]="savingsPageRouterLink">
      <button stbButton="text" iconType="add" [setIconBefore]="true" data-trackid="start-own-savings-for-pension">
        {{ "engagementList.savings.emptyButtonText" | fms }}
      </button>
    </a>
  </ng-template>

  <ng-container
    *appLet="'engagementList.employment.title' | fms as title"
    [ngTemplateOutlet]="ContractList"
    [ngTemplateOutletContext]="{
      headerText: getHeaderText({ title }),
      contractItems: employmentContractItems$ | async,
      skeletons: 1,
      readyToRender: readyToRender,
      emptyContractItemsOutlet: emptyEmployment,
      ctaOutlet: ctaOutlet
    }"
  >
  </ng-container>

  <ng-template #ctaOutlet>
    <app-offentlig-tjeneste-cta-card></app-offentlig-tjeneste-cta-card>

    @if (showAddAfpCard$ | async) {
      <app-add-afp-cta-card></app-add-afp-cta-card>
    }
  </ng-template>

  <ng-template #emptyEmployment>
    <button
      stbButton="text"
      iconType="arrow-right"
      [setIconAfter]="true"
      (click)="openLink('engagementList.employment.emptyButtonUrl')"
      data-trackid="contractList-emptyEmployment"
    >
      {{ "engagementList.employment.emptyButtonText" | fms }}
    </button>
  </ng-template>

  <ng-container
    *appLet="'engagementList.public.title' | fms as title"
    [ngTemplateOutlet]="ContractList"
    [ngTemplateOutletContext]="{
      headerText: getHeaderText({ title }),
      contractItems: publicContractItems$ | async,
      skeletons: 1,
      defaultExpanded: true,
      readyToRender: readyToRender,
      emptyContractItemsOutlet: emptyPublic
    }"
  ></ng-container>

  <ng-template #emptyPublic>
    {{ "engagementList.public.emptyText" | fms }}
  </ng-template>
</div>

<ng-template
  #ContractList
  let-headerText="headerText"
  let-contractItems="contractItems"
  let-skeletons="skeletons"
  let-defaultExpanded="defaultExpanded"
  let-readyToRender="readyToRender"
  let-emptyContractItemsOutlet="emptyContractItemsOutlet"
  let-ctaOutlet="ctaOutlet"
>
  <div class="content">
    <div class="content-header">
      <h2 class="column-title">
        {{ headerText.title }}
      </h2>
    </div>

    @if (readyToRender) {
      <div class="content-list">
        @if (contractItems.length > 0) {
          <mat-accordion [@listAnimation]="contractItems.length" multi>
            @for (contractItem of contractItems; track trackById($index, contractItem)) {
              <app-contract
                [id]="contractItem.contract.getIdentifier()"
                [contract]="toAnyEngagement(contractItem.contract)"
                [loading]="false"
                [expanded]="contractItem.expanded"
                [defaultExpanded]="defaultExpanded && contractItems.length === 1"
              ></app-contract>
            }
          </mat-accordion>
        } @else {
          <div class="empty-contract-items">
            <ng-container [ngTemplateOutlet]="emptyContractItemsOutlet"></ng-container>
          </div>
        }
        <ng-container [ngTemplateOutlet]="ctaOutlet"></ng-container>
      </div>
    } @else {
      <div class="content-loading">
        @for (i of [].constructor(skeletons); track i) {
          <app-contract [loading]="true"></app-contract>
        }
      </div>
    }
  </div>
</ng-template>
