@switch (consentCardState$ | async) {
  @case (ConsentCardState.NavConsent) {
    <app-nav-consent-cta-card></app-nav-consent-cta-card>
  }
  @case (ConsentCardState.NorskPensjonConsent) {
    <app-norskpensjon-consent-cta-card
      [title]="title"
      [enableSalaryRequirement]="isPensionPlanContext"
    ></app-norskpensjon-consent-cta-card>
  }
  @case (ConsentCardState.AuthRequirement) {
    <app-norskpensjon-requirements-cta-card></app-norskpensjon-requirements-cta-card>
  }
}
