<div class="cards-wrapper">
  @if (showCard$ | async) {
    <app-dashboard-promo-card
      [promoCard]="cardData$ | async | fms"
      trackId="dashboard-promo-card-stock-market-2024"
      (cardClick)="onCardClick($event)"
    ></app-dashboard-promo-card>
  }
</div>
<div class="cards-wrapper"></div>
