@if (buttonsToRender$ | async; as buttonsToRender) {
  @if (individualButtons) {
    @for (button of buttonsToRender; track button.key) {
      <button
        stbButton="text"
        iconType="add"
        [setIconBefore]="true"
        (click)="newEngagementDialog(button.dialogComponent)"
        [attr.data-trackid]="button.trackIdFmsKey"
      >
        {{ button.textFmsKey | fms }}
      </button>
    }
  } @else {
    @if (buttonsToRender.length > 0) {
      <button
        stbButton="contained"
        iconType="add"
        [setIconBefore]="true"
        data-trackid="add-pension-savings"
        (click)="openCombinedNewEngagementsDialog(buttonsToRender)"
      >
        {{ "pensionPlan.addEngagementsButtons.combinedButton.text" | fms }}
      </button>
    }
  }
}
