@if (savings$ | async; as savings) {
  <div class="summary-table" [class.summary-table-responsive]="responsive">
    <div class="summary-row">
      <div class="summary-label">
        {{ "engagement.label.savingsRate.yearly" | fms }}
      </div>
      <div class="summary-value">
        {{ savings.employerAnnualSavingsAmount | currencyFormatterPipe: "start" }}
      </div>
    </div>
    <div class="summary-row">
      <div class="summary-label">
        {{
          "engagement.label.savingsRate.basic"
            | fms
              : {
                  args: {
                    bottomSalary: savings.savingsRate.basic?.bottomSalary | currencyFormatterPipe: "start",
                    topSalary: savings.savingsRate.basic?.topSalary | currencyFormatterPipe: "start",
                  },
                }
        }}
      </div>
      <div class="summary-value">{{ savings.savingsRate.basic?.savingsRatePercent }} %</div>
    </div>
    <div class="summary-row">
      <div class="summary-label">
        {{
          "engagement.label.savingsRate.additional"
            | fms
              : {
                  args: {
                    bottomSalary: savings.savingsRate.additional?.bottomSalary | currencyFormatterPipe: "start",
                    topSalary: savings.savingsRate.additional?.topSalary | currencyFormatterPipe: "start",
                  },
                }
        }}
      </div>
      <div class="summary-value">{{ savings.savingsRate.additional?.savingsRatePercent }} %</div>
    </div>
  </div>
}
