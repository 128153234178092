import { Injectable } from "@angular/core";
import { catchError, map, Observable, of } from "rxjs";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { Nullable } from "src/app/utils/utils";
import { AnySavingsAndPensionEngagement } from "../../../services/prognoses-services/savings-and-pension.service";
import { ContractDetailsService, isContractWithHoldings } from "./contract-details.service";

export interface HoldingsAndSavingsProfile {
  id: string;
  isAnbefaltPensjon: boolean;
  holdings: Holding[];
  hasDownscaling: boolean | undefined;
}

export interface Holding {
  name: Nullable<string>;
  actualDistribution: Nullable<number>;
}

@Injectable()
export class FetchHoldingsAndAllocationService {
  constructor(private readonly contractDetailsService: ContractDetailsService) {}

  public fetchHoldingsAndSavingsProfile(
    engagement: AnySavingsAndPensionEngagement,
  ): Observable<HoldingsAndSavingsProfile> {
    return this.contractDetailsService.fetchContractDetails(engagement).pipe(
      map((contract) => {
        if (isContractWithHoldings(contract)) {
          const { id, holdings, allocationCode } = contract;
          if (id && holdings?.funds && holdings.funds.length > 0 && allocationCode) {
            return toHoldingsAndSavingsProfile(id, holdings.funds, allocationCode);
          }
        }
        return generateEmptyHoldingsAndSavingsProfile();
      }),
      catchError(() => of(generateEmptyHoldingsAndSavingsProfile())),
    );
  }
}

function toHoldingsAndSavingsProfile(
  id: string,
  funds: Graph.PortfolioInsightFund[],
  allocationCode: string,
): HoldingsAndSavingsProfile {
  const allocation = allocationCode as InvestmentReturns.AllocationEnum;
  return {
    id,
    isAnbefaltPensjon: isAnbefaltPensjon(allocation),
    hasDownscaling: hasDownscaling(allocation),
    holdings: funds.map((fund) => ({
      name: fund?.name,
      actualDistribution: fund?.actualDistribution,
    })),
  };
}

function isAnbefaltPensjon(allocationCode: InvestmentReturns.AllocationEnum): boolean {
  return [
    InvestmentReturns.AllocationEnum.AS1,
    InvestmentReturns.AllocationEnum.LN,
    InvestmentReturns.AllocationEnum.LN0,
    InvestmentReturns.AllocationEnum.LN1,
    InvestmentReturns.AllocationEnum.LN3,
  ].includes(allocationCode);
}

function hasDownscaling(allocationCode: InvestmentReturns.AllocationEnum): boolean | undefined {
  const hasDownscalingAllocationCode =
    isAnbefaltPensjon(allocationCode) ||
    [
      InvestmentReturns.AllocationEnum.STD,
      InvestmentReturns.AllocationEnum.STDNED1,
      InvestmentReturns.AllocationEnum.STDNED2,
      InvestmentReturns.AllocationEnum.STDNED3,
    ].includes(allocationCode);

  if (hasDownscalingAllocationCode) {
    return true;
  }
  if (allocationCode === InvestmentReturns.AllocationEnum.REBA1) {
    return undefined;
  }
  return false;
}

function generateEmptyHoldingsAndSavingsProfile(): HoldingsAndSavingsProfile {
  return {
    id: "",
    isAnbefaltPensjon: false,
    holdings: [],
    hasDownscaling: undefined,
  };
}
