@if (withTitle) {
  <h2 class="stb-mb2">{{ title$ | async }}</h2>
}

<div class="content">
  @if (withIcon) {
    <div class="left">
      <img [src]="icon$ | async" alt />
    </div>
  }

  <div class="right">
    <div [innerHtml]="shortDisclaimerText$ | async"></div>

    @if (isExpanded$ | async) {
      <div [innerHtml]="longDisclaimerText$ | async"></div>
    }

    @if (isExpandable) {
      <button
        class="link"
        [attr.data-trackid]="
          (isExpanded$ | async) ? 'minimize-info-about-calculations' : 'see-more-info-about-calculations'
        "
        (click)="toggle()"
      >
        {{ buttonText$ | async }}
      </button>
    }
  </div>
</div>
