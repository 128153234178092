import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import {
  ContractAction,
  ContractActionBuilder,
  ContractActionType,
} from "src/app/modules/shared/services/contract-actions/contract-actions.types";
import { AnyEngagement } from "src/app/services/engagements.service";
import { FmsService } from "src/app/services/fms.service";
import { getIsSmartAccount } from "src/app/utils/engagement.utils";

@Injectable()
export class LinkToOnlineBankingActionBuilder implements ContractActionBuilder {
  constructor(private readonly fmsService: FmsService) {}

  public predicate(engagement: AnyEngagement): boolean {
    return getIsSmartAccount(engagement);
  }

  public async build(): Promise<ContractAction> {
    const [linkLabel, linkUrl, linkTrackId] = await Promise.all([
      this.getLinkLabel(),
      this.getLinkUrl(),
      this.getLinkTrackId(),
    ]);

    return {
      type: ContractActionType.External,
      linkLabel,
      linkUrl,
      linkTrackId,
    };
  }

  private getLinkLabel(): Promise<string> {
    return firstValueFrom(this.fmsService.translateAsync<string>("contractActions.linkToOnlineBanking.linkLabel"));
  }

  private getLinkUrl(): Promise<string> {
    return firstValueFrom(this.fmsService.translateAsync<string>("contractActions.linkToOnlineBanking.linkUrl"));
  }

  private getLinkTrackId(): Promise<string> {
    return firstValueFrom(this.fmsService.translateAsync<string>("contractActions.linkToOnlineBanking.linkTrackId"));
  }
}
