@if ((ofaVendors?.length ?? 0) > 0) {
  <div>
    <p class="paragraph-long-bold">{{ headerTextFmsId | fms }}</p>
    @for (ofaVendor of ofaVendors; track ofaVendor.vendorName) {
      <ul class="ofa-vendor-list">
        <li class="ofa-vendor-list-item">
          {{ ofaVendor.vendorName }}
          ({{ getServiceTimeText(ofaVendor) | async }})
        </li>
      </ul>
    }
  </div>
}
