<ng-container *appLet="totalPercentChange$ | async as totalPercentChange">
  @if (totalPercentChange === null || totalPercentChange === undefined) {
    <app-text-skeleton></app-text-skeleton>
  } @else {
    <span
      class="tooltip-wrapper"
      matTooltip="{{ 'percentageTotalPensionChange.tooltip' | fms: { args: tooltipFmsArgs$ | async } }}"
    >
      @if (totalPercentChange > 0) {
        <img class="comparison-icon" [src]="'common.arrowChangeIncreaseIcon' | fms" alt />
        <span class="positive percentage">{{ Math.abs(totalPercentChange) | number: "1.0-0" }} %</span>
        <span class="text">{{ "percentageTotalPensionChange.increase.text" | fms }}</span>
      } @else if (totalPercentChange < 0) {
        <img class="comparison-icon" [src]="'common.arrowChangeDecreaseIcon' | fms" alt />
        <span class="negative percentage">{{ Math.abs(totalPercentChange) | number: "1.0-0" }} %</span>
        <span class="text">{{ "percentageTotalPensionChange.decrease.text" | fms }}</span>
      }
    </span>
  }
</ng-container>
