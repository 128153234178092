import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ItpUnfundedEngagement } from "src/app/models/engagements/savings-and-pension/pension-fund-engagement.model";
import { EngagementBody } from "src/app/modules/pension-plan/components/engagement/engagement-body/engagement-body.component";
import { PensionFundHolding } from "src/app/services/api/savings-and-pension-queries.types";
import { Nullable } from "src/app/utils/utils";
import { HoldingsDialogComponent } from "../../holdings-dialog/holdings-dialog.component";

@Component({
  selector: "app-engagement-details-itp-unfunded",
  templateUrl: "./engagement-details-itp-unfunded.component.html",
  styleUrls: ["../engagement-details-shared.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngagementDetailsItpUnfundedComponent implements EngagementBody, OnInit {
  @Input()
  public engagement!: ItpUnfundedEngagement;
  @Input()
  public hasError!: boolean;
  @Input()
  public showSimulationParameters = true;
  @Input()
  public responsive = true;

  public contractNumber: Nullable<string>;
  public yearlyDeposit: Nullable<number>;
  public holdings: Nullable<PensionFundHolding[]>;

  constructor(private readonly dialog: MatDialog) {}

  ngOnInit(): void {
    this.contractNumber = this.engagement.getContractNumberCustomer();
    this.yearlyDeposit = this.engagement.getYearlyDeposit();
    this.holdings = this.engagement.getPensionFundHoldings();
  }

  public async openDialogWithFunds(): Promise<void> {
    this.dialog.open(HoldingsDialogComponent, {
      autoFocus: false,
      restoreFocus: false,
      panelClass: "large-dialog-padding",
      data: { holdings: this.holdings ?? [] },
    });
  }
}
