import { Observable } from "rxjs";
import { AnyEngagement } from "src/app/services/engagements.service";

export enum ContractActionType {
  External = "external",
  Internal = "internal",
  Button = "button",
}

interface Link {
  linkTrackId: string;
  linkLabel: string;
  linkIcon?: string;
}
export interface ExternalLink extends Link {
  linkUrl: string;
  type: ContractActionType.External;
}

export interface InternalLink extends Link {
  linkRouter: string;
  type: ContractActionType.Internal;
}

export interface ButtonLink extends Link {
  onClick: () => void;
  type: ContractActionType.Button;
}

export type ContractAction = InternalLink | ExternalLink | ButtonLink;

export interface ContractActionBuilder {
  predicate(engagement: AnyEngagement): boolean | Observable<boolean>;
  build(
    engagement: AnyEngagement,
  ): ContractAction | ContractAction[] | Promise<ContractAction> | Promise<ContractAction[]>;
}

export type ContractActionsErrorReporter = (errors: Error[]) => void;
