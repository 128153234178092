@if (renderTrigger$ | async) {
  <ng-content></ng-content>
} @else {
  @for (number of rowCount$ | async; track number) {
    <div>
      <!-- &nbsp; to ensure same height as parent text element -->
      <span class="blank-5">&nbsp;</span>
      <span class="blob-skeleton blob-50">&nbsp;</span>
      <span class="blank-5">&nbsp;</span>
      <span class="blob-skeleton blob-15">&nbsp;</span>
      <span class="blank-5">&nbsp;</span>
      <span class="blob-skeleton blob-15">&nbsp;</span>
      <span class="blank-5">&nbsp;</span>
    </div>
  }
}
