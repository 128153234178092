<mat-card>
  <div class="item-content">
    <div class="item-group-one">
      <div>
        <div class="item-title">{{ title }}</div>
        <div class="item-label" data-hj-suppress>{{ description }}</div>
      </div>
      <img class="item-icon" [src]="iconSrc" alt />
    </div>

    <div class="item-separator"></div>

    <div class="item-group-two">
      @for (field of fields; track field.value) {
        <div>
          <div class="item-label">
            {{ field.label }}
          </div>
          @if (field.value) {
            <div class="item-value value-header-alignment">
              {{ field.value }}
            </div>
          }
          @if (!field.value) {
            <div class="item-no-value value-header-alignment">
              <i>information</i>
              <span>
                {{ "engagement.error.amountUnavailable" | fms }}
              </span>
            </div>
          }
        </div>
      }
    </div>
  </div>
</mat-card>
