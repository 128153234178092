<p>
  {{ purpose }}

  <button
    class="opt-button mat-typography"
    *appLet="consentValue$ | async as consentValue"
    (click)="setConsentValue(!consentValue)"
    data-trackId="dashboardPurchaseAssistanceConsent-optToggle"
  >
    @if (consentValue) {
      {{ "purchaseAssistanceConsent.optOut" | fms }}
    } @else {
      {{ "purchaseAssistanceConsent.optIn" | fms }}
    }
  </button>
</p>
