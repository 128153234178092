import { gql } from "@apollo/client/core";

export const ALL_PROGNOSIS_FIELDS_FRAGMENT = gql`
  fragment AllPrognosisFields on Prognosis {
    payoutPlan {
      age
      amount
    }
    simulationStatus {
      severity
      messageKey
    }
  }
`;

export const NORSKPENSJON_CONTRACT_UNIQUE_FIELDS_FRAGMENT = gql`
  fragment NorskpensjonContractUniqueFields on NorskpensjonContract {
    softId
    startAge
    endAge
  }
`;

export const NORSKPENSJON_CONTRACT_FIELDS_FRAGMENT = gql`
  fragment NorskpensjonContractFields on NorskpensjonContract {
    ...NorskpensjonContractUniqueFields
    contractNumber
    category
    causeLackingGrading
    causeNotCalculated
    employer {
      organizationNumber
      name
    }
    holdings {
      totalMarketValue
      passiveMarketValue
      activeMarketValue
    }
    note
    pensionProvider {
      name
    }
    productDescription
    subCategory
  }
  ${NORSKPENSJON_CONTRACT_UNIQUE_FIELDS_FRAGMENT}
`;

export const ALL_AGE_COVERAGE_FIELDS_FRAGMENT = gql`
  fragment AllAgeCoverageFields on AgeCoverage {
    fromAndIncludingAge
    coverageTypeCode
    fromAndIncludingAge
    toNotIncludingAge
    lifelongPayout
    annualAmount
    coverageName {
      norwegian
      english
    }
  }
`;

const CORE_CONTRACT_FIELDS = `
    id
    productNameFmsKey
`;

const DEFAULT_CONTRACT_FIELDS = `
    ${CORE_CONTRACT_FIELDS}
    contractNumber
    customerContractReference
    contractNavigation {
      contractDetailsLink
      availableActions {
        actionType
        actionNavigationLink
        actionStatus
      }
    }
`;

const EMPLOYER = `
    employer {
      name
    }
`;

export const ALIS_CONTRACT_FIELDS_FRAGMENT = gql`
  fragment AlisContractFields on AlisContract {
    ${DEFAULT_CONTRACT_FIELDS}
    balance
    premiumYearlyAmount
  }
`;

export const EKSTRAPENSJON_EMPLOYMENT_FIELDS_FRAGEMENT = gql`
  fragment EkstrapensjonEmploymentContractFields on EkstrapensjonEmploymentContract {
      ${DEFAULT_CONTRACT_FIELDS}
      accountId
      allocationCode
      customerSuppliedContractName
      participantId
      productCode
      keyValues {
        marketValue {
          value
        }
      }
      savingsAgreements {
          yearlyAmount
          periodicAmount
          status
      }
      employer {
        name
      }
      employerAnnualSavingsAmount
      underPayout
  }
`;

export const LINK_CONTRACT_FIELDS_FRAGMENT = gql`
  fragment LinkContractFields on LinkContract {
    ${DEFAULT_CONTRACT_FIELDS}
    accountId
    allocationCode
    customerSuppliedContractName
    participantId
    productCode
    keyValues {
      marketValue {
        value
      }
    }
    savingsAgreements {
      yearlyAmount
      periodicAmount
      status
    }
    underPayout
  }
`;

export const EPK_CONTRACT_FIELDS_FRAGMENT = gql`
  fragment EpkContractFields on EpkContract {
    ${DEFAULT_CONTRACT_FIELDS}
    ${EMPLOYER}
    allocationCode
    productCode
    keyValues {
      marketValue {
        value
      }
    }
    underPayout
  }
`;

export const PKB_CONTRACT_FIELDS_FRAGMENT = gql`
  fragment PkbContractFields on PkbContract {
    ${DEFAULT_CONTRACT_FIELDS}
    ${EMPLOYER}
    allocationCode
    productCode
    keyValues {
      marketValue {
        value
      }
    }
    underPayout
  }
`;
export const EPK_FLEKSIBEL_CONTRACT_FIELDS = gql`
  fragment EpkFleksibelContractFields on EpkFleksibelContract {
    ${DEFAULT_CONTRACT_FIELDS}
    customerSuppliedContractName
    productCode
    keyValues {
      marketValue {
        value
      }
    }
  }
`;
export const EPK_FLEKSIBEL_PKB_CONTRACT_FIELDS = gql`
  fragment EpkFleksibelPkbContractFields on EpkFleksibelPkbContract {
    ${DEFAULT_CONTRACT_FIELDS}
    allocationCode
    customerSuppliedContractName
    productCode
    keyValues {
      marketValue {
        value
      }
    }
    underPayout
  }
`;
export const EPK_EMPLOYMENT_CONTRACT_FIELDS = gql`
  fragment EpkEmploymentContractFields on EpkEmploymentContract {
    ${DEFAULT_CONTRACT_FIELDS}
    ${EMPLOYER}
    productCode
    epkFleksibelContract {
      id
    }
    underPayout
  }
`;

export const FMI_CONTRACT_FIELDS = gql`
  fragment FmiContractFields on FmiContract {
    ${DEFAULT_CONTRACT_FIELDS}
    allocationCode
    productCode
    keyValues {
      marketValue {
        value
      }
    }
    selectableInvestmentProfileInPayoutPeriod
    compressionLimits
    underPayout
  }
`;

export const YTP_CONTRACT_FIELDS = gql`
  fragment YtpContractFields on YtpContract {
    ${DEFAULT_CONTRACT_FIELDS}
    ${EMPLOYER}
    productCode
    compressionLimits
    underPayout
  }
`;

export const FRIPOLISE_CONTRACT_FIELDS = gql`
  fragment FripoliseContractFields on FripoliseContract {
    ${DEFAULT_CONTRACT_FIELDS}
    ${EMPLOYER}
    productCode
    compressionLimits
    underPayout
  }
`;

export const HYBRID_MED_INVESTERINGSVALG_CONTRACT_FIELDS = gql`
  fragment HybridMedInvesteringsvalgContractFields on HybridMedInvesteringsvalgContract {
    ${DEFAULT_CONTRACT_FIELDS}
    ${EMPLOYER}
    allocationCode
    productCode
    keyValues {
      marketValue {
        value
      }
    }
    underPayout
  }
`;
export const HYBRID_MED_GARANTI_CONTRACT_FIELDS = gql`
  fragment HybridMedGarantiContractFields on HybridMedGarantiContract {
    ${DEFAULT_CONTRACT_FIELDS}
    ${EMPLOYER}
    salary
    productCode
    underPayout
  }
`;
export const HYBRID_PENSJONSBEVIS_CONTRACT_FIELDS = gql`
  fragment HybridPensjonsbevisContractFields on HybridPensjonsbevisContract {
    ${DEFAULT_CONTRACT_FIELDS}
    ${EMPLOYER}
    productCode
    underPayout
  }
`;

export const PENSION_FUND_UNFUNDED_YTP_UNDER_PAYOUT_FIELDS = gql`
  fragment PensionFundUnfundedYtpUnderPayoutContractFields on PensionFundUnfundedYtpUnderPayoutContract {
    ${DEFAULT_CONTRACT_FIELDS}
    ${EMPLOYER}
    productCode
    underPayout
  }
`;

export const PENSION_FUND_YTP_CONTRACT_FIELDS_FRAGMENT = gql`
  fragment PensionFundYtpContractFields on PensionFundYtpContract {
    ${CORE_CONTRACT_FIELDS}
    ${EMPLOYER}
    customerContractReference
    paidUpPolicy
    underPayout
  }
`;
export const PENSION_FUND_ITP_CONTRACT_FIELDS_FRAGMENT = gql`
  fragment PensionFundItpContractFields on PensionFundItpContract {
    ${CORE_CONTRACT_FIELDS}
    ${EMPLOYER}
    customerContractReference
    pensionCapitalCertificate
    epkContract {
      id
    }
    underPayout
    holdings {
      totalMarketValue {
        value
      }
      totalReturn {
        value
      }
      yearlyDeposit {
        value
      }
      funds {
        name
        actualDistribution
      }
    }
    contractNavigation {
      contractDetailsLink
      availableActions {
        actionType
        actionNavigationLink
        actionStatus
      }
    }
  }
`;
