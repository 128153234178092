import { AuthOptions } from "@storebrand-digital/auth";
import { StbStage, getStbStage } from "../utils/storebrand-staging";
import { ALLOWED_ACR, ALLOWED_AMR } from "../constants/auth.constants";

export const KeycloakClientId = "storebrand.pension.smart-pensjon-web";

enum KeycloakRealm {
  Advisor = "corporate",
  User = "storebrand",
}

enum KeycloakUrl {
  NonProdUser = "https://id-t.storebrand.no/auth",
  NonProdAdvisor = "https://id-t.intra.storebrand.no/auth/",
  ProdUser = "https://id.storebrand.no/auth",
  ProdAdvisor = "https://id.intra.storebrand.no/auth/",
}

const getLogoutRedirectUri = (): string => {
  const environment = getStbStage(getIsAdvisor());

  return environment !== StbStage.Produksjon
    ? "https://www-t.storebrand.no/logg-ut/"
    : "https://www.storebrand.no/logg-ut/";
};

export function getKeycloakOptions(): AuthOptions {
  const isAdvisor = getIsAdvisor();
  const environment = getStbStage(isAdvisor);
  const scope = getScope(getCMIDfromQuery());

  return {
    config: {
      realm: getRealm(isAdvisor),
      clientId: getClientId(),
      url: getUrl(environment, isAdvisor),
    },
    init: {
      scope,
    },
    login: {
      scope,
      allowedAMR: ALLOWED_AMR,
      allowedACR: ALLOWED_ACR,
    },
    logout: {
      redirectUri: getLogoutRedirectUri(),
    },
    autoRefresh: true,
  };
}

const getCMIDfromQuery = (): string | undefined => {
  const url = new URLSearchParams(window.location.search);
  return url.get("cmid") ?? undefined;
};

const getIsAdvisor = (): boolean => {
  const cmid = getCMIDfromQuery();
  return !!cmid;
};

function getRealm(isAdvisor: boolean): string {
  return isAdvisor ? KeycloakRealm.Advisor : KeycloakRealm.User;
}

function getClientId(): string {
  return KeycloakClientId;
}

function getUrl(environment: StbStage, isAdvisor: boolean): string {
  if (environment === StbStage.Produksjon) {
    return isAdvisor ? KeycloakUrl.ProdAdvisor : KeycloakUrl.ProdUser;
  }
  return isAdvisor ? KeycloakUrl.NonProdAdvisor : KeycloakUrl.NonProdUser;
}

function getScope(userCmid: string | undefined): string {
  // Note: we are waiting for USB to adopt new token before we remove corporate.internal
  const scopes = userCmid
    ? ["corporate.internal", "on_behalf_of:cm_id:" + userCmid]
    : [
        "storebrand.bank.private",
        "storebrand.life.private",
        "storebrand.funds.private",
        "storebrand.pensionfunds.private",
        "storebrand.profile.supplied",
        "storebrand.capital.private",
        "cm_id",
      ];

  return scopes.join(" ");
}
