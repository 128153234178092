import { CurrencyPipe, DatePipe, DecimalPipe, registerLocaleData } from "@angular/common";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import localeNb from "@angular/common/locales/nb";
import { APP_INITIALIZER, ErrorHandler, Injector, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouteReuseStrategy, Router } from "@angular/router";
import { HttpClientInMemoryWebApiModule } from "angular-in-memory-web-api";
import { enableMapSet } from "immer";
import { ConceptPageComponent } from "src/app/core/pages/concept-page/concept-page.component";
import { ContactUsPageComponent } from "src/app/core/pages/contact-us-page/contact-us-page.component";
import { setAppInjector } from "src/app/injector";
import { APP_ERROR_HANDLER_OPTIONS, AppErrorHandler } from "src/app/providers/app-error-handler.provider";
import { CustomRouteReuseStrategy } from "src/app/providers/custom-route-reuse-strategy.provider";
import { ApiHomeService } from "src/app/services/api-home.service";
import { CustomerService } from "src/app/services/customer.service";
import { EngagementsService } from "src/app/services/engagements.service";
import { ErrorsService } from "src/app/services/errors.service";
import { FmsService } from "src/app/services/fms.service";
import { HttpCache, HttpCacheImpl } from "src/app/services/http-cache.service";
import { InternalSavingsService } from "src/app/services/internal-savings.service";
import { KeycloakService } from "src/app/services/keycloak.service";
import { AfpService } from "src/app/services/prognoses-services/afp.service";
import { FetchPrognosesRunningJobsService } from "src/app/services/running-jobs/fetch-prognoses-running-jobs.service";
import { Monitoring } from "src/app/utils/monitoring";
import { environment } from "src/environments/environment";
import { AppComponent } from "./app.component";
import { routing } from "./app.routing";
import { DashboardCardComponent } from "./core/pages/dashboard/dashboard-cards/dashboard-card/dashboard-card.component";
import { DashboardCardsComponent } from "./core/pages/dashboard/dashboard-cards/dashboard-cards.component";
import { DashboardLinkListComponent } from "./core/pages/dashboard/dashboard-link-list/dashboard-link-list.component";
import { DashboardPromoCardComponent } from "./core/pages/dashboard/dashboard-promo-cards/dashboard-promo-card/dashboard-promo-card.component";
import { DashboardPromoCardsComponent } from "./core/pages/dashboard/dashboard-promo-cards/dashboard-promo-cards.component";
import { DashboardComponent } from "./core/pages/dashboard/dashboard.component";
import { ErrorsPageComponent } from "./core/pages/errors-page/errors-page.component";
import { keycloakInitializer } from "./initializers/keycloak.initializer";
import { CachingInterceptor } from "./interceptors/caching.interceptor";
import { CorrelationInterceptor } from "./interceptors/correlation.interceptor";
import { KeycloakBearerInterceptor } from "./interceptors/keycloak-bearer.interceptor";
import { ActionIpsService } from "./modules/features/pension-actions/components/action-ips/action-ips.service";
import { GraphqlClientsModule } from "./modules/graphql-clients/graphql-clients.module";
import { MockGraphqlClientsModule } from "./modules/graphql-clients/mock-graphql-clients.module";
import { SharedModule } from "./modules/shared/shared.module";
import { ExtrapensionService } from "./services/extrapension.service";
import { InMemoryDataService } from "./services/in-memory-data.service";
import { ActionEpkService } from "./modules/features/pension-actions/services/action-epk.service";

enableMapSet();
registerLocaleData(localeNb, "nb");

const COMPONENTS = [
  AppComponent,
  ConceptPageComponent,
  ContactUsPageComponent,
  DashboardComponent,
  DashboardCardComponent,
  DashboardCardsComponent,
  DashboardPromoCardComponent,
  DashboardPromoCardsComponent,
  DashboardLinkListComponent,
  ErrorsPageComponent,
];

const PIPES = [CurrencyPipe, DatePipe, DecimalPipe];

const SERVICES = [
  AfpService,
  EngagementsService,
  ApiHomeService,
  CustomerService,
  ErrorsService,
  FmsService,
  ExtrapensionService,
  ActionIpsService,
  InternalSavingsService,
  KeycloakService,
  FetchPrognosesRunningJobsService,
  ActionEpkService,
];

@NgModule({
  declarations: [COMPONENTS],
  exports: [],
  bootstrap: [AppComponent],
  imports: [
    routing,
    BrowserAnimationsModule,
    BrowserModule,
    SharedModule,
    environment.production
      ? []
      : HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, {
          passThruUnknownUrl: true,
          post204: true,
        }),
    environment.offlineMode ? MockGraphqlClientsModule : GraphqlClientsModule,
  ],
  providers: [
    PIPES,
    SERVICES,
    { provide: LOCALE_ID, useValue: "nb" },
    { provide: APP_ERROR_HANDLER_OPTIONS, useValue: { logErrors: true } },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CachingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CorrelationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true,
    },
    {
      provide: HttpCache,
      useClass: HttpCacheImpl,
    },
    {
      provide: Monitoring.getTraceService(),
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: keycloakInitializer,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => (): void => {
        // noop
      },
      deps: [Monitoring.getTraceService()],
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  constructor(injector: Injector) {
    setAppInjector(injector);
  }
}
