<stb-form [label]="'publicPensionwithdrawalPercentage.label' | fms" size="flex" [disabled]="disabled">
  @if (withdrawalPercentages; as percentages) {
    <select [ngModel]="selectedPercent$ | async" (change)="onWithdrawalPercentageChange($event)" [disabled]="disabled">
      @for (percentage of percentages; track percentage.value) {
        <option [value]="percentage.value">
          {{ percentage.viewValue }}
        </option>
      }
    </select>
  }
</stb-form>
