import { Injectable } from "@angular/core";
import { getHasContractNumber } from "src/app/modules/shared/services/contract-actions/contract-actions.utils";
import { AnyEngagement } from "src/app/services/engagements.service";
import { FmsService } from "src/app/services/fms.service";
import { getIsInvestableEngagement } from "src/app/utils/engagement.utils";
import { ContractActionBuilder, ContractActionType, ExternalLink } from "../contract-actions.types";

@Injectable()
export class AddLumpSumActionBuilder implements ContractActionBuilder {
  constructor(private readonly fmsService: FmsService) {}

  public predicate(engagement: AnyEngagement): boolean {
    return [engagement].filter(getHasContractNumber).some(getIsInvestableEngagement);
  }

  public build(engagement: AnyEngagement): ExternalLink {
    return {
      type: ContractActionType.External,
      linkLabel: this.getLinkLabel(),
      linkUrl: this.getLinkUrl(engagement),
      linkTrackId: this.getLinkTrackId(),
    };
  }

  private getLinkLabel(): string {
    return this.fmsService.instant("contractActions.addLumpSum.linkLabel");
  }

  private getLinkUrl(engagement: AnyEngagement): string {
    return this.fmsService.instant("contractActions.addLumpSum.linkUrl", {
      args: {
        contractNumber: engagement.getContractNumber(),
        exitUrl: window.location.href,
      },
    });
  }

  private getLinkTrackId(): string {
    return "addLumpSumAction";
  }
}
