<ng-container *appLet="backUrl$ | async as backUrl">
  @if (isInternal$ | async) {
    <a
      class="button-back"
      [attr.data-trackid]="trackId"
      [routerLink]="backUrl"
      [queryParams]="currentQueryParamsWithoutExitUrl$ | async"
    >
      <i>chevron-left</i>
      <span>{{ "breadcrumbs.backButtonText" | fms }}</span>
    </a>
  }

  @if (isExternal$ | async) {
    <a class="button-back" [attr.data-trackid]="trackId" [href]="backUrl">
      <i>chevron-left</i>
      <span>{{ "breadcrumbs.backButtonText" | fms }}</span>
    </a>
  }
</ng-container>
