<div class="container">
  <app-page-title
    [text]="'contactUsPage.pageTitle' | fms"
    [description]="'contactUsPage.pageDescription' | fms"
  ></app-page-title>

  <section class="limited-container">
    <h2 class="stb-mb2">{{ "contactUsPage.general.title" | fms }}</h2>
    <p>{{ "contactUsPage.general.description" | fms }}</p>

    <ng-container
      [ngTemplateOutlet]="ContactInfo"
      [ngTemplateOutletContext]="{
        messageUrl: 'contactUsPage.general.message.url' | fms,
        messageText: 'contactUsPage.general.message.text' | fms,
        messageTrackid: 'pension-question-send-message',
        phone: 'contactUsPage.general.phone' | fms,
        phoneTrackid: 'pension-question-call',
      }"
    ></ng-container>
  </section>

  <section class="limited-container">
    <h2 class="stb-mb2">{{ "contactUsPage.withdrawal.title" | fms }}</h2>
    <p>{{ "contactUsPage.withdrawal.description" | fms }}</p>

    <ng-container
      [ngTemplateOutlet]="ContactInfo"
      [ngTemplateOutletContext]="{
        messageUrl: 'contactUsPage.withdrawal.message.url' | fms,
        messageText: 'contactUsPage.withdrawal.message.text' | fms,
        messageTrackid: 'pension-payment-send-message',
        phone: 'contactUsPage.withdrawal.phone' | fms,
        phoneTrackid: 'pension-payment-call',
      }"
    ></ng-container>
  </section>

  <div class="cards">
    <div class="card">
      <img [src]="'contactUsPage.card.first.imageUrl' | fms" alt />
      <div [innerHTML]="'contactUsPage.card.first.text' | fms"></div>
    </div>

    <div class="card">
      <img [src]="'contactUsPage.card.second.imageUrl' | fms" alt />
      <div [innerHTML]="'contactUsPage.card.second.text' | fms"></div>
    </div>

    <div class="card">
      <img [src]="'contactUsPage.card.third.imageUrl' | fms" alt />
      <div [innerHTML]="'contactUsPage.card.third.text' | fms"></div>
    </div>
  </div>

  <section class="limited-container">
    <h2 class="stb-mb2">{{ "contactUsPage.explore.title" | fms }}</h2>
    <p>{{ "contactUsPage.explore.text" | fms }}</p>

    <button
      stbButton="text"
      class="explore-button"
      [routerLink]="articlesRoute"
      data-trackid="contactUs-articles"
      [setIconAfter]="true"
      iconType="arrow-right"
    >
      {{ "contactUsPage.explore.buttonText" | fms }}
    </button>
  </section>
</div>

<ng-template
  #ContactInfo
  let-messageText="messageText"
  let-messageUrl="messageUrl"
  let-messageTrackid="messageTrackid"
  let-phone="phone"
  let-phoneTrackid="phoneTrackid"
>
  <a
    class="secure-message-link"
    [href]="messageUrl"
    (click)="openSecureMessageInNewWindow($event, messageUrl)"
    [attr.data-trackid]="messageTrackid"
    >{{ messageText }}</a
  >

  <div class="contact-info">
    <i>support</i>
    <a [href]="'tel:' + phone" [attr.data-trackid]="phoneTrackid">{{ phone }}</a>
  </div>
</ng-template>
