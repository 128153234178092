import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import {
  ContractAction,
  ContractActionBuilder,
  ContractActionType,
} from "src/app/modules/shared/services/contract-actions/contract-actions.types";
import { RouteKey, routes } from "src/app/modules/smart-account/routes";
import { AnyEngagement } from "src/app/services/engagements.service";
import { FmsService } from "src/app/services/fms.service";
import { getIsSmartAccount } from "src/app/utils/engagement.utils";
import { composePaths } from "src/app/utils/router.utils";

@Injectable()
export class LinkToSmartAccountDetailsActionBuilder implements ContractActionBuilder {
  constructor(private readonly fmsService: FmsService) {}

  public predicate(engagement: AnyEngagement): boolean {
    return getIsSmartAccount(engagement);
  }

  public async build(): Promise<ContractAction> {
    const [linkLabel, linkRouter, linkTrackId] = await Promise.all([
      this.getLinkLabel(),
      this.getLinkRouter(),
      this.getLinkTrackId(),
    ]);

    return {
      type: ContractActionType.Internal,
      linkLabel,
      linkRouter,
      linkTrackId,
    };
  }

  private getLinkLabel(): Promise<string> {
    return firstValueFrom(this.fmsService.translateAsync<string>("contractActions.linkToSmartAccount.linkLabel"));
  }

  private getLinkRouter(): string {
    return composePaths(routes, RouteKey.Root);
  }

  private getLinkTrackId(): Promise<string> {
    return Promise.resolve("linkToSmartAccount");
  }
}
