<stb-form [label]="label | fms" size="flex" [hint]="tip" [disabled]="disabled">
  @if (endPayoutAgeRange; as range) {
    <select
      [ngModel]="selectedAge"
      (change)="onPayoutAgeChange($event)"
      id="startPayoutAge"
      [disabled]="disabled || !range.length"
    >
      @for (val of range; track val) {
        <option [value]="val">
          {{ val | year }}
        </option>
      }
    </select>
  }
</stb-form>
