@if (shouldShowStepUpCard$ | async) {
  <div class="step-up-container">
    <h2>{{ "authenticationStepup.title" | fms }}</h2>
    <p>
      {{ "authenticationStepup.content.paragraph" | fms }}
    </p>
    <p>{{ "authenticationStepup.content.subParagraph" | fms }}</p>
    <div class="buttons-wrapper">
      <button stbButton="contained" type="button" (click)="stepUpAuth()">
        {{ "authenticationStepup.button.loginBankID.label" | fms }}
      </button>
      <button stbButton="outlined" type="button" (click)="closeStepUp()">
        {{ "common.closeIcon" | fms }}
      </button>
    </div>
  </div>
}
