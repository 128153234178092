@if (hasEmployerSalary$ | async) {
  <div [innerHTML]="employerDesciption$ | async"></div>
  <app-salary-provider-radio-form-field [formGroup]="form"></app-salary-provider-radio-form-field>
} @else {
  <div [innerHTML]="'onboardingSalaryStep.description.noEmployer.html' | fms"></div>
}
<app-yearly-income-gross-form-field
  [class.visible]="showYearlyIncomeGrossFormField$ | async"
  [formGroup]="form"
  [isOnboardingContext]="true"
  (keydownEnter)="onEnter()"
></app-yearly-income-gross-form-field>
