import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import * as Highcharts from "highcharts";
import exporting from "highcharts/modules/exporting";
import { isEmpty } from "lodash-es";
import { combineLatest, delay, Observable, of, Subject, switchMap } from "rxjs";
import { filter, map, startWith, takeUntil } from "rxjs/operators";
import {
  ChartService,
  ChartTranslations,
  generateXAxisCategoryOption,
  NumberedSeriesColumnOptions,
} from "src/app/services/chart.service";
import { CustomerService } from "src/app/services/customer.service";
import { StartPayoutAgeService } from "src/app/services/start-payout-age.service";
import { StorebrandOnlyService } from "src/app/services/storebrand-only.service";
import { memoizeObject$ } from "src/app/utils/rxjs/select";
import { getIsNotNullable } from "src/app/utils/utils";
import { defaultOptions } from "./chart.default-options";

// eslint-disable-next-line @typescript-eslint/no-var-requires
require("highcharts/highcharts-more")(Highcharts);
// eslint-disable-next-line @typescript-eslint/no-var-requires
require("highcharts/modules/accessibility")(Highcharts);
exporting(Highcharts);

type HighchartsSeriesOptions =
  | Highcharts.SeriesSplineOptions
  | NumberedSeriesColumnOptions
  | Highcharts.SeriesArearangeOptions;

interface Options extends Highcharts.Options {
  series?: HighchartsSeriesOptions[];
}

/**
 * Highcharts.Axis is missing two props from highcharts.d.ts
 * Extending Highcharts.Axis and adding them here
 */
interface AxisWithMissingProps extends Highcharts.Axis {
  tickInterval?: number;
  options:
    | ({ minorTickAmount?: number } & Highcharts.XAxisOptions)
    | ({ minorTickAmount?: number } & Highcharts.YAxisOptions)
    | ({ minorTickAmount?: number } & Highcharts.ZAxisOptions);
}

@Component({
  selector: "app-chart",
  templateUrl: "./chart.component.html",
  styleUrls: ["./chart.component.scss", "./chart.component.tooltip.scss", "./chart.component.xaxis.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ChartComponent implements OnInit, OnDestroy {
  @Input()
  public series$!: Observable<HighchartsSeriesOptions[]>;
  @Input()
  public options$!: Observable<Highcharts.Options>;
  @Input()
  public oneToOne = false;
  @Input()
  public height = 400;
  @Input()
  public skeletons = 15;
  @Input()
  public skeletonsWidth = 75;
  @Input()
  public description = "";
  @Input()
  public showLifelongText = false;
  @Output()
  public initialized = new EventEmitter<Highcharts.Chart>();

  public Highcharts = Highcharts;
  public optionsWithSeries$!: Observable<Options>;
  public showSkeletons$!: Observable<boolean>;

  private chartInstance?: Highcharts.Chart;
  private readonly destroy$: Subject<void> = new Subject();

  constructor(
    private readonly chartService: ChartService,
    private readonly customerService: CustomerService,
    private readonly startPayoutAgeService: StartPayoutAgeService,
    private readonly storebrandOnlyService: StorebrandOnlyService,
  ) {
    wrapAxisGetMinorTickPositions();
  }

  @HostBinding("attr.style")
  public get style(): string {
    return `height: ${this.height}px`;
  }

  public ngOnInit(): void {
    const customerAge$ = this.storebrandOnlyService
      .getIsEnabled()
      .pipe(switchMap((storebrandOnly) => (storebrandOnly ? of(undefined) : this.customerService.age$)));

    this.optionsWithSeries$ = memoizeObject$(
      combineLatest([
        this.options$,
        this.series$,
        this.startPayoutAgeService.getStartPayoutAge(),
        this.chartService.xAxisCompleteRange$,
        this.chartService.chartTranslations$,
        customerAge$,
      ]).pipe(
        map(([options, series, startPayoutAge, range, translations, customerAge]) => ({
          ...this.mapOptions(options, startPayoutAge, range, translations, customerAge),
          series: series ?? [],
        })),
        takeUntil(this.destroy$),
      ),
    );

    this.showSkeletons$ = this.optionsWithSeries$.pipe(
      map(() => false),
      startWith(true),
    );

    this.initialized
      .pipe(
        delay(0),
        filter(() => !isEmpty(this.chartInstance)),
        takeUntil(this.destroy$),
      )
      .subscribe(() => this.chartInstance?.reflow());
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  public emitChartInstance(chartInstance: Highcharts.Chart): void {
    this.chartInstance = chartInstance;
    this.initialized.emit(chartInstance);
  }

  private mapOptions(
    options: Highcharts.Options,
    startPayoutAge: number,
    xAxisRange: number[],
    translations: ChartTranslations,
    customerAge?: number,
  ): Highcharts.Options {
    return Highcharts.merge(
      defaultOptions(startPayoutAge, translations, generateXAxisCategoryOption(xAxisRange, customerAge)),
      options,
      { accessibility: { description: this.description } },
    );
  }
}

function wrapAxisGetMinorTickPositions(): void {
  typedHighchartsWrap<AxisWithMissingProps>(Highcharts.Axis.prototype, "getMinorTickPositions", (thisArg, proceed) => {
    const amount = thisArg.options?.minorTickAmount;
    const interval = thisArg.tickInterval;
    const minorTicks: number[] = [];

    if (
      getIsNotNullable(interval) &&
      getIsNotNullable(amount) &&
      Highcharts.isNumber(amount) &&
      thisArg.tickPositions
    ) {
      thisArg.tickPositions.forEach((tick) => {
        Array(amount)
          .fill(0)
          .forEach((_, i) => {
            minorTicks.push(tick + (interval * (i + 1)) / (amount + 1));
          });
      });
      return minorTicks;
    } else {
      return proceed.apply(thisArg, [].slice.call([proceed], 1));
    }
  });
}

function typedHighchartsWrap<T>(
  obj: T,
  method: string,
  func: (thisArg: T, proceed: Function, ...args: any[]) => any,
): void {
  Highcharts.wrap(obj, method, function (proceed, ...args) {
    // @ts-ignore
    const thisArg = this as T;
    return func(thisArg, proceed, ...args);
  });
}
