<div class="summary-table" [class.summary-table-responsive]="responsive">
  @if (showSimulationParameters) {
    <div class="summary-row mobile-only">
      <div class="summary-label">
        {{ "engagement.label.totalPayout" | fms }}
      </div>
      <div class="summary-value">
        @if (!hasError) {
          {{ getTotalPayout() | currencyFormatterPipe: "start" }}
        } @else {
          <app-amount-unavailable></app-amount-unavailable>
        }
      </div>
    </div>
    <div class="summary-row mobile-only">
      <div class="summary-label">
        {{ "engagement.label.payoutDuration" | fms }}
      </div>
      <div class="summary-value">
        @if (!hasError) {
          {{ getDuration() }}
        } @else {
          <app-amount-unavailable></app-amount-unavailable>
        }
      </div>
    </div>
  }
</div>

@if (engagement.contract.periods.length > 1) {
  <p>
    {{ "engagementBodyOther.periodAverage" | fms }}
  </p>
  <div class="summary-table">
    @for (period of engagement.contract.periods; track period) {
      <div class="summary-row">
        <div class="summary-label">
          {{ getPeriodLabel(period) }}
        </div>
        <div class="summary-value">
          {{ period.payoutAmountPerYear | currencyFormatterPipe: "start" }}
        </div>
      </div>
    }
  </div>
}

@if (engagement.contract.replaceOffentligTjenestepensjon) {
  <app-contract-type-description
    [engagement]="engagement"
    [showDescription]="showDescription"
  ></app-contract-type-description>
}

@if (engagement.contract.replaceOffentligTjenestepensjon) {
  <p [innerHTML]="'otherPension.offentligTjenestepensjon' | fms"></p>
}
