@if (showSimulationParameters) {
  <div class="afp-warning">
    <img [src]="'afpEngagement.secondInfo.iconSrc' | fms" alt />
    <p [innerHTML]="'afpEngagement.secondInfo.text' | fms"></p>
  </div>
}

<app-contract-type-description
  [engagement]="engagement"
  [showDescription]="showDescription"
></app-contract-type-description>

@if (!showSimulationParameters) {
  <p class="contract-type-description-link" [innerHtml]="'afpEngagement.contractTypeDescriptionLink' | fms"></p>
}
