<div
  class="actions-container"
  [class.narrow]="narrow"
  [class.expandable]="isExpandable$ | async"
  [class.expanded]="isExpanded$ | async"
>
  <div class="actions" [ngStyle]="{ 'height.px': cardHeight$ | async }">
    <ng-container #actions></ng-container>

    <div class="gradient"></div>
  </div>

  <div class="load-more">
    @if (isExpanded$ | async) {
      <button
        stbButton="text"
        (click)="isExpanded$.next(false)"
        [setIconAfter]="true"
        data-track-id="actions-loadLess"
        iconType="arrow-up"
      >
        {{ "actions.loadLess" | fms }}
      </button>
    } @else {
      <button
        stbButton="text"
        (click)="isExpanded$.next(true)"
        [setIconAfter]="true"
        data-track-id="actions-loadMore"
        iconType="arrow-down"
      >
        {{ "actions.loadMore" | fms }}
      </button>
    }
  </div>
</div>
