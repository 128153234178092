import { Router } from "@angular/router";
import { isEqual, last } from "lodash-es";
import { RouteKey, routes } from "../modules/pension-plan/routes";
import {
  getIsNotNullable,
  getIsNullable,
  Nullable,
  removeQueryParams,
  removeUrlQueryParamsAndFragment,
} from "../utils/utils";
import { FmsKey } from "./fms/fms";

export enum PensionPlanProgressionState {
  New = "New",
  Continue = "Continue",
  Finished = "Finished",
}
export interface PensionPlanRoute extends TimedRoute {
  url: string;
  nextText: FmsKey;
  previousText: FmsKey;
}

interface PensionPlanStep {
  route: TimedRoute;
  intros: TimedRoute[];
  /**
   * Used to omit a route from the stepable plan.
   * To access the route use getNext().
   */
  omit?: boolean;
}

export interface TimedRoute {
  key: RouteKey;
}

/**
 * The order of this list is significant and is used to
 * determine both the order of the links in the menu and
 * the user's progress through the pension plan.
 *
 * The pension plan may or may not use all routes, depending
 * on the user context (e.g. public/private), whether it
 * should use intro steps or not, and the composition of the
 * plan. To compose a plan that navigates through a sub-set
 * of the routes with a correct step count, use the 'omit'
 * flag. Omitted routes are still acessible by calling
 * getNext().
 */
const pensionPlanSteps: PensionPlanStep[] = [
  {
    route: { key: RouteKey.Agreements },
    intros: [{ key: RouteKey.AgreementsIntro }],
  },
  {
    route: { key: RouteKey.Accumulation },
    intros: [{ key: RouteKey.AccumulationIntro }],
  },
  {
    route: { key: RouteKey.Needs },
    intros: [{ key: RouteKey.NeedsIntro }],
  },
  {
    route: { key: RouteKey.Actions },
    intros: [{ key: RouteKey.ActionsIntro }],
  },
  {
    route: { key: RouteKey.Summary },
    intros: [{ key: RouteKey.SummaryIntro }],
    omit: true,
  },
];

export function getCurrentRoute(input: Router | string): PensionPlanRoute | undefined {
  const inputUrl = input instanceof Router ? input.url : input;
  const urlWithoutQueryParams = removeUrlQueryParamsAndFragment(inputUrl);

  return [getStepByUrl(urlWithoutQueryParams)]
    .filter(getIsNotNullable)
    .flatMap(toIntroAndKey)
    .map(toPlanRoute)
    .find(({ url }) => url === urlWithoutQueryParams);
}

export function getStepByUrl(url: string): PensionPlanStep | undefined {
  const urlWithoutQueryParams = removeQueryParams(url, []);

  return pensionPlanSteps.find(
    ({ route: step, intros }) =>
      getUrlForRouteKey(step.key) === urlWithoutQueryParams ||
      intros.some((introKey) => getUrlForRouteKey(introKey.key) === urlWithoutQueryParams),
  );
}

/**
 * Returns true if the given route is considered the last step of the
 * pension plan, meaning either the last unomitted step or the last
 * omitted step.
 */
export function getIsLastRoute(route: Nullable<PensionPlanRoute>): boolean {
  return getIsNullable(route) ? false : getIsIntro(route) ? false : getIsLastStep(toStep(route));
}

function getIsLastStep(step: Nullable<PensionPlanStep>): boolean {
  return getIsNotNullable(step) ? step === last(getUnomittedSteps()) || step === last(pensionPlanSteps) : false;
}

export function getStepRouteKeys(): TimedRoute[] {
  return getUnomittedSteps().flatMap(toIntroAndKey);
}

export function getNext(currentRoute: RouteKey | undefined): PensionPlanStep {
  const { step: currentStep, index: currentIndex } = locateStep(currentRoute, pensionPlanSteps);
  if (getIsNullable(currentStep) || getIsNullable(currentIndex)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return pensionPlanSteps.at(0)!;
  }

  const nextIndex = currentIndex + 1;

  return pensionPlanSteps.at(nextIndex) ?? currentStep;
}

export function getLastValidRoute(...routeKeys: (RouteKey | undefined)[]): RouteKey | undefined {
  const inputKeys = routeKeys.filter(getIsNotNullable);
  return getAllSteps()
    .filter((step) => inputKeys.some((key) => key === step.key))
    .at(-1)?.key;
}

export function getUrlForRouteKey(key: RouteKey): string {
  return `/${routes[RouteKey.Root]}/${routes[key]}`;
}

export function getRouteForUrl(inputUrl: Nullable<string>): PensionPlanRoute | undefined {
  return pensionPlanSteps
    .flatMap(toIntroAndKey)
    .map(toPlanRoute)
    .find(({ url }) => inputUrl?.includes(url));
}

function getUnomittedSteps(): PensionPlanStep[] {
  return pensionPlanSteps.filter((plan) => !(plan?.omit ?? false));
}

function getAllSteps(): TimedRoute[] {
  return pensionPlanSteps.flatMap(toIntroAndKey);
}

export function toPlanRoutes(timedRoutes: TimedRoute[]): PensionPlanRoute[] {
  return timedRoutes.map(toPlanRoute);
}

function toPlanRoute({ key }: TimedRoute): PensionPlanRoute {
  return {
    key,
    url: getUrlForRouteKey(key),
    nextText: "pensionPlan.progress.next",
    previousText: "pensionPlan.progress.previous",
  };
}

function locateStep(
  routeKey: RouteKey | undefined,
  steps: PensionPlanStep[],
): {
  step: PensionPlanStep | undefined;
  index: number | undefined;
} {
  const index = steps.findIndex(
    ({ route, intros }) => route.key === routeKey || intros.some((intro) => intro.key === routeKey),
  );

  if (index < 0) {
    return { step: undefined, index: undefined };
  }

  const step = steps.at(index);

  return { step, index };
}

function getIsIntro(route: PensionPlanRoute): boolean {
  return pensionPlanSteps
    .map(toIntroOnly)
    .flatMap(toPlanRoutes)
    .some((_route) => isEqual(route, _route));
}

function toIntroOnly(step: PensionPlanStep): TimedRoute[] {
  return step.intros;
}

function toIntroAndKey(step: PensionPlanStep): TimedRoute[] {
  return [...step.intros, step.route];
}

function toStep(route: PensionPlanRoute): PensionPlanStep | undefined {
  return locateStep(route.key, pensionPlanSteps).step;
}
