import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Observable, of, switchMap } from "rxjs";
import { combineLatestWith, map } from "rxjs/operators";
import { FmsService } from "src/app/services/fms.service";
import { KeycloakService } from "src/app/services/keycloak.service";
import { NorskpensjonRequirementsService } from "src/app/services/prognoses-services/norskpensjon-requirements.service";

interface RequirementItem {
  id: number;
  compliant$: Observable<boolean>;
  compliantText$: Observable<string>;
  notCompliantText$: Observable<string>;
}

enum FmsKeys {
  AuthLevelCompliantText = "NorskpensjonRequirementsCtaCard.AuthLevelCompliantText",
  AuthLevelNotCompliantText = "NorskpensjonRequirementsCtaCard.AuthLevelNotCompliantText",
  ConsentCompliantText = "NorskpensjonRequirementsCtaCard.ConsentCompliantText",
  ConsentNotCompliantText = "NorskpensjonRequirementsCtaCard.ConsentNotCompliantText",
  Title = "NorskpensjonRequirementsCtaCard.Title",
  Description = "NorskpensjonRequirementsCtaCard.Description",
  ButtonText = "NorskpensjonRequirementsCtaCard.ButtonText",
}

@Component({
  selector: "app-norskpensjon-requirements-cta-card",
  templateUrl: "./norskpensjon-requirements-cta-card.component.html",
  styleUrls: ["./norskpensjon-requirements-cta-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NorskpensjonRequirementsCtaCardComponent {
  public readonly requirementItems: RequirementItem[];
  public readonly title = this.fmsService.instant(FmsKeys.Title);
  public readonly description = this.fmsService.instant(FmsKeys.Description);
  public readonly buttonText = this.fmsService.instant(FmsKeys.ButtonText);

  constructor(
    private readonly norskpensjonRequirementsService: NorskpensjonRequirementsService,
    private readonly keycloakService: KeycloakService,
    private readonly fmsService: FmsService,
  ) {
    this.requirementItems = [
      {
        id: 1,
        compliant$: this.norskpensjonRequirementsService.getLatestHasAuthLevelRequirement(),
        compliantText$: of(this.fmsService.instant<string>(FmsKeys.AuthLevelCompliantText)),
        notCompliantText$: of(this.fmsService.instant<string>(FmsKeys.AuthLevelNotCompliantText)),
      },
      {
        id: 2,
        compliant$: this.norskpensjonRequirementsService.getLatestHasConsentRequirement(),
        compliantText$: of(this.fmsService.instant<string>(FmsKeys.ConsentCompliantText)),
        notCompliantText$: of(this.fmsService.instant<string>(FmsKeys.ConsentNotCompliantText)),
      },
    ];
  }

  public getStatusIcon(item: RequirementItem): Observable<string> {
    return item.compliant$.pipe(
      switchMap((compliant) =>
        compliant
          ? this.fmsService.translateAsync<string>("NorskpensjonRequirementsCtaCard.checkIcon")
          : this.fmsService.translateAsync<string>("NorskpensjonRequirementsCtaCard.crossIcon"),
      ),
    );
  }

  public getStatusText(item: RequirementItem): Observable<string> {
    const { compliant$, compliantText$, notCompliantText$ } = item;

    return compliant$.pipe(
      combineLatestWith(compliantText$, notCompliantText$),
      map(([compliant, compliantText, notCompliantText]) => (compliant ? compliantText : notCompliantText)),
    );
  }

  public stepUpAuth(): void {
    this.keycloakService.stepUpAuth();
  }
}
