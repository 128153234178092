import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Observable, combineLatest } from "rxjs";
import { combineLatestWith, distinctUntilChanged, map } from "rxjs/operators";
import { CUSTOMIZED_SIMULATION_AGE_DROPDOWN_VALUE } from "src/app/constants/technical.constants";
import { ClientDataService } from "src/app/services/customer-supplied-data/client-data.service";
import { FmsService } from "src/app/services/fms.service";
import { PensionPlanService } from "src/app/services/pension-plan.service";
import { FetchPrognosesRunningJobsService } from "src/app/services/running-jobs/fetch-prognoses-running-jobs.service";
import { StartPayoutAgeService } from "src/app/services/start-payout-age.service";

const DEFAULT_LABEL = "simulationAge.age.label";

@Component({
  selector: "app-start-payout-age-form-field",
  templateUrl: "./start-payout-age-form-field.component.html",
  styleUrls: ["./start-payout-age-form-field.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartPayoutAgeFormFieldComponent {
  @Input()
  public label: string = this.fmsService.instant(DEFAULT_LABEL);
  @Input()
  public tip?: string;

  @Output()
  public selectionChange = new EventEmitter<number>();
  @Output()
  public highlightChange = new EventEmitter<boolean>();

  public CUSTOMIZED_SIMULATION_AGE_DROPDOWN_VALUE = CUSTOMIZED_SIMULATION_AGE_DROPDOWN_VALUE.toString();

  public selectedAge$: Observable<string>;
  public startPayoutAgeRange$: Observable<string[]>;
  public showCustomWithdrawalOrderOption$: Observable<boolean>;
  public isCurrentYearLoaded$: Observable<boolean>;

  constructor(
    private readonly fmsService: FmsService,
    private readonly clientDataService: ClientDataService,
    private readonly fetchPrognosesRunningJobsService: FetchPrognosesRunningJobsService,
    private readonly pensionPlanService: PensionPlanService,
    private readonly startPayoutAgeService: StartPayoutAgeService,
  ) {
    this.startPayoutAgeRange$ = this.startPayoutAgeService.getStartPayoutAgeRange().pipe(
      map((range) => range.map((i) => i.toString())),
      distinctUntilChanged(),
    );

    this.selectedAge$ = this.startPayoutAgeService.getStartPayoutAge().pipe(
      map(String),
      combineLatestWith(
        this.clientDataService.simulationParametersByEngagementEnable$.pipe(map(({ enable }) => enable)),
      ),
      map(([startPayoutAge, simulationParametersByEngagementEnable]) =>
        simulationParametersByEngagementEnable ? this.CUSTOMIZED_SIMULATION_AGE_DROPDOWN_VALUE : startPayoutAge,
      ),
      distinctUntilChanged(),
    );

    const simulationByEngagementEnabled$ = this.clientDataService.simulationParametersByEngagementEnable$.pipe(
      map(({ enable }) => enable),
    );

    const hasSimulationParametersByEngagement$ = this.clientDataService.clientData$.pipe(
      map((clientData) => clientData.simulationParametersByEngagement?.length > 0),
    );

    this.showCustomWithdrawalOrderOption$ = combineLatest([
      simulationByEngagementEnabled$,
      hasSimulationParametersByEngagement$,
    ]).pipe(map(([enabled, hasSimulationParametersByEngagement]) => enabled || hasSimulationParametersByEngagement));

    this.isCurrentYearLoaded$ = this.fetchPrognosesRunningJobsService.isCurrentYearLoaded$;
  }

  public onPayoutAgeChange(event: Event): void {
    const value = (event.target as HTMLFormElement).value;

    const age = Number(value);
    this.selectionChange.emit(age);
    this.pensionPlanService.changeStartPayoutAge(age);
  }
}
