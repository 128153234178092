<p>
  {{ "onboardingConsents.description" | fms }}
</p>

<div>
  <h4>{{ "onboardingConsents.dataStorage.title" | fms }}</h4>
  <p>
    {{ "onboardingConsents.dataStorage.description" | fms }}
  </p>

  @if (storageRadioButtons$ | async; as storageRadioButtons) {
    <stb-radio-buttons
      name="storageRadio"
      [horizontal]="true"
      [group]="storageRadioButtons"
      [message]="'onboardingConsents.dataStorage.error' | fms"
      [warning]="(storageConsentControl.statusChanges | async) === 'INVALID'"
      (valueChanged)="setStorageControl($event)"
      data-trackid="onboarding-dataStorage-radioButton"
    >
    </stb-radio-buttons>
  }

  <h4>{{ "onboardingConsents.norskPensjon.title" | fms }}</h4>
  <p>{{ "onboardingConsents.norskPensjon.description" | fms }}</p>
  @if (norskPensjonRadioButtons$ | async; as norskPensjonRadioButtons) {
    <stb-radio-buttons
      name="norskPensjonRadio"
      [horizontal]="true"
      [group]="norskPensjonRadioButtons"
      [message]="'onboardingConsents.norskPensjon.error' | fms"
      [warning]="(norskPensjonConsentControl.statusChanges | async) === 'INVALID'"
      (valueChanged)="setNorskPensjonControl($event)"
      data-trackid="onboarding-norskPensjon-radioButton"
    >
    </stb-radio-buttons>
  }
</div>
