<div class="summary-table details-wrapper bottom-border" [class.summary-table-responsive]="responsive">
  @if (showSimulationParameters) {
    <div class="summary-row mobile-only">
      <div class="summary-label">
        {{ "engagement.label.totalPayout" | fms }}
      </div>
      <div class="summary-value">
        @if (hasError) {
          <app-amount-unavailable></app-amount-unavailable>
        } @else {
          {{ totalPayout$ | async | currencyFormatterPipe: "start" }}
        }
      </div>
    </div>
  }

  @if (showSimulationParameters) {
    <div class="summary-row mobile-only">
      <div class="summary-label">
        {{ "engagement.label.payoutDuration" | fms }}
      </div>
      <div class="summary-value">
        @if (!hasError) {
          {{ duration$ | async }}
        } @else {
          <app-amount-unavailable></app-amount-unavailable>
        }
      </div>
    </div>
  }

  @if (hasHoldings$ | async) {
    <ng-container>
      <div class="summary-row">
        <div class="summary-label">
          {{ "engagement.label.savingProfileFund" | fms }}
        </div>
        @if (holdings$ | async; as holdings) {
          <div class="summary-value" data-hj-suppress>
            <a data-trackid="engagementDetail-savingProfileFondLink" (click)="openDialogWithFunds()">
              <ng-container
                [ngTemplateOutlet]="
                  (isAnbefaltPensjon$ | async)
                    ? anbefaltPensjon
                    : holdings.length === 1
                      ? singleHolding
                      : multipleHoldings
                "
              >
                <ng-template #anbefaltPensjon
                  >{{ "engagement.label.savingProfileFund.anbefaltPensjon" | fms }}
                </ng-template>
                <ng-template #singleHolding>
                  {{ holdings[0].name }}
                </ng-template>
                <ng-template #multipleHoldings
                  >{{ "engagement.label.savingProfileFund.multipleHoldings" | fms }}
                </ng-template>
              </ng-container>
            </a>
          </div>
        }
      </div>
    </ng-container>
  }

  @if (yearlyPremiumAmount$ | async; as premiumAnnualAmount) {
    <div class="summary-row">
      <div class="summary-label">
        {{ "engagement.label.premiumYearlyAmount" | fms }}
      </div>
      <div class="summary-value">
        {{ premiumAnnualAmount | currencyFormatterPipe: "start" }}
      </div>
    </div>
  }

  @if (equalGraphEngagement$ | async; as equalGraphEngagement) {
    <app-savings-rate-summary-rows
      [engagement]="equalGraphEngagement"
      [responsive]="responsive"
    ></app-savings-rate-summary-rows>
  }

  @if (contractNumber$ | async; as contractNumber) {
    <div class="summary-row">
      @if (isBank$ | async) {
        <div class="summary-label">
          {{ "engagement.label.accountNumber" | fms }}
        </div>
        <div class="summary-value" data-hj-suppress>
          {{ contractNumber | accountNumberFormatter }}
        </div>
      } @else {
        <div class="summary-label">
          {{ "engagement.label.contractNumber" | fms }}
        </div>
        <div class="summary-value" data-hj-suppress>
          {{ contractNumber }}
        </div>
      }
    </div>
  }
</div>

<div>
  <app-contract-type-description
    [engagement]="engagement$ | async"
    [showPensionPlanEntry]="showPensionPlanEntry"
    [showDescription]="showDescription"
  ></app-contract-type-description>

  @if (showPayoutSimulationAlternatives$ | async) {
    <app-payout-simulation-alternatives [engagement$]="engagement$"></app-payout-simulation-alternatives>
  }
</div>
