@if (isAvailable(value)) {
  <span class="value">
    {{ value | currencyFormatterPipe }}
  </span>
  <span class="suffix">{{ suffix }}</span>
} @else {
  <span class="value">
    {{ "engagement.error.amountUnavailable" | fms }}
  </span>
}
