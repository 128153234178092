import { immerable, produce } from "immer";
import { Observable, first, map } from "rxjs";
import {
  NORSKPENSJON_PUBLIC_PENSION_UNKNOWN_PENSION_PROVIDER_NAME,
  PensionArea,
  PensionGroup,
} from "src/app/constants/business.constants";
import { EngagementName, EngagementSimulationStatus } from "src/app/models/pension.model";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { Nullable } from "src/app/utils/utils";
import { PAYOUT_DURATIONS, PayoutDuration, getPayoutPlanDuration } from "../generic-engagement.model";
import { getAverageAnnualPayout, getTotalPayout } from "../generic-graph-engagement.model";
import { getSimulationStatus } from "../graph-engagement.model";
import { AbstractNorskpensjonEngagement } from "./abstract-norskpensjon-engagement.model";

export class NorskpensjonEngagement extends AbstractNorskpensjonEngagement<Graph.NorskpensjonContract> {
  [immerable] = true;
  public readonly sortIndexWithinPayer!: number;

  constructor(
    public contract: Graph.NorskpensjonContract,
    prognosis?: Graph.Prognosis,
  ) {
    super(contract, prognosis);
  }

  public withSortIndex(index: number): NorskpensjonEngagement {
    return produce(this, (draft) => {
      draft.sortIndexWithinPayer = index;
    });
  }

  public getNameAsync(): Observable<EngagementName> {
    const fmsService = this.getFmsService();

    return fmsService.translateAsync<string>("sharedProductNames.np.unnamed_product").pipe(
      first(),
      map((unnamedProductName) => ({
        name: this.contract?.productDescription ?? unnamedProductName,
        payer: this.contract?.employer?.name,
        supplier: getProviderNameOrNone(this.contract?.pensionProvider?.name),
      })),
    );
  }

  public getPayoutFromAge(): number | undefined {
    return this.getPayoutPlan().at(0)?.age || undefined;
  }

  public getPayoutToAge(): number | undefined {
    return this.getPayoutPlan().at(-1)?.age || undefined;
  }

  public getPayoutPlan(): Graph.PayoutPlan[] {
    return (this.prognosis?.payoutPlan ?? []) as Graph.PayoutPlan[];
  }

  public getContinousPeriods(): unknown[] {
    return [];
  }

  public getSoftId(): Nullable<string> {
    return this.contract?.softId;
  }

  public getIdentifier(): string {
    const id = this.contract?.softId ?? "";
    const idWithIndex = `${id}-${this.sortIndexWithinPayer}`;

    return this.sortIndexWithinPayer ? idWithIndex : id;
  }

  public getSimulationStatus(): EngagementSimulationStatus[] {
    return ([] as Graph.SimulationStatus[]).concat(this.prognosis?.simulationStatus ?? {}).map(getSimulationStatus);
  }

  public getAverageAnnualPayout(): number | null {
    const payoutPlan = this.getPayoutPlan();

    return getAverageAnnualPayout(payoutPlan);
  }

  public getTotalPayout(): number | null {
    const payoutPlan = this.getPayoutPlan();
    const totalPayout = getTotalPayout(payoutPlan);

    return totalPayout || null;
  }

  public getFirstYearPayout(): number {
    const firstYear = this.getPayoutPlan().at(0);
    return firstYear?.amount ?? 0;
  }

  public getPayoutDuration(): PayoutDuration {
    if (this.isLifelongPayout()) {
      return PAYOUT_DURATIONS.infinite;
    }

    return getPayoutPlanDuration(this.getPayoutPlan());
  }

  public isLifelongPayout(): boolean {
    return !!this.prognosis?.lifeLong;
  }

  public getPensionArea(): PensionArea {
    return this.contract.category as PensionArea;
  }

  public getPensionGroup(): PensionGroup {
    return this.contract.subCategory as PensionGroup;
  }

  public getContractNumber(): string {
    return this.contract?.contractNumber || "";
  }

  public getBalance(): Nullable<number> {
    return this.contract?.holdings?.totalMarketValue ?? null;
  }

  public isPublicPensionFromNorskPensjon(): boolean {
    return this.getPensionArea() === PensionArea.OffentligTjenestepensjon;
  }

  public getCauseLackingGrading(): string {
    return this.contract?.causeLackingGrading ?? "";
  }

  public isSavingsEngagement(): boolean {
    return this.getPensionArea() === PensionArea.IndividuelleOrdninger;
  }

  public isPensjonskapitalbevis(): boolean {
    return this.getPensionGroup() === PensionGroup.Pensjonskapitalbevis;
  }

  /**
   * @deprecated used for diffing purposes. To be disposed with TRM-2589.
   */
  public getApiResource(): string {
    return "savings-graphql";
  }
}

export function getProviderNameOrNone(providerName: Nullable<string>): string | undefined {
  const isNullByValue = providerName === NORSKPENSJON_PUBLIC_PENSION_UNKNOWN_PENSION_PROVIDER_NAME;

  return providerName && !isNullByValue ? providerName : undefined;
}
