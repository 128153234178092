import { isPlainObject, sortBy } from "lodash-es";
import { Observable, combineLatest, first, map } from "rxjs";
import { ContractTypeOf, PrognosisTypeOf } from "src/app/models/engagements/generic-engagement.model";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { PublicPensionContractStatus } from "src/app/services/prognoses-services/public-pension.service.vars";
import { toArrayOrEmpty } from "src/app/utils/array";
import { Boundary } from "src/app/utils/getNumberWithinBounds";
import { isTypeNumber } from "src/app/utils/number";
import { select } from "src/app/utils/rxjs/select";
import { EngagementName, EngagementSimulationStatus } from "../pension.model";
import { PAYOUT_DURATIONS, PayoutDuration } from "./generic-engagement.model";
import { GenericGraphEngagement } from "./generic-graph-engagement.model";
import { StaticPublicPensionPrognosisParameters } from "../../services/api/public-pension-prognosis-parameters-static.service";

export type Contract = Pick<
  Graph.PublicPensionContract,
  "contractNumber" | "coverages" | "employer" | "id" | "productNameFmsKey" | "status" | "contractNavigation"
>;

export const PUBLIC_PENSION_ENGAGEMENT_ID = "OffentligStb";

export class PublicPensionEngagement extends GenericGraphEngagement<
  Contract[],
  Graph.PublicPensionPrognosis,
  Graph.PublicPensionPrognosisInput
> {
  public constructor(
    public readonly prognosisParameters: StaticPublicPensionPrognosisParameters,
    contract: ContractTypeOf<PublicPensionEngagement>,
    prognosis?: PrognosisTypeOf<PublicPensionEngagement>,
  ) {
    super(contract, prognosis);
  }

  public isActive(): boolean {
    return this.getContracts().some((contract) => contract.status === PublicPensionContractStatus.Active);
  }

  public getContracts(): Contract[] {
    return this.contract;
  }

  public getIdentifier(): string {
    return PUBLIC_PENSION_ENGAGEMENT_ID;
  }

  /** @deprecated Use getNameAsync instead */
  public getName(age?: number): EngagementName {
    return select(this.getNameAsync(age));
  }

  public getNameAsync(age?: number | undefined): Observable<EngagementName> {
    const sortedContracts = sortBy(
      this.getContracts(),
      (contract) => contract.status === PublicPensionContractStatus.Active,
    );

    const name$ = this.getFirstContractName().pipe(
      map((name) => (this.getIsAfpPayoutAtAge(age) ? name.concat(" (AFP)") : name)),
    );

    return combineLatest([
      name$,
      this.getFirstContractDescription(sortedContracts),
      this.getFirstContractPayer(sortedContracts),
    ]).pipe(
      first(),
      map(([name, description, payer]) => ({
        name,
        description,
        payer,
        supplier: this.isActive() ? "Storebrand" : undefined,
      })),
    );
  }

  public getPayoutPlan(): Graph.PayoutPlan[] {
    return ([] as Graph.PayoutPlan[])
      .concat(toArrayOrEmpty(this.prognosis?.paymentPlanAfp62))
      .concat(toArrayOrEmpty(this.prognosis?.paymentPlanAfp65))
      .concat(toArrayOrEmpty(this.prognosis?.paymentPlanPension))
      .filter(isPlainObject);
  }

  public getPayoutFromAge(): number | undefined {
    return this.getPayoutPlan().at(0)?.age || undefined;
  }

  public getPayoutToAge(): number | undefined {
    return this.getPayoutPlan().at(-1)?.age || undefined;
  }

  public getSimulationStatus(): EngagementSimulationStatus[] {
    const DEFAULT_SIMULATION_STATUSES = [{ severity: Graph.SimulationSeverity.Ok }];
    return this.prognosis?.statusMessages?.map(mapStatusMessageToSimulationStatus) ?? DEFAULT_SIMULATION_STATUSES;
  }

  public getFirstYearPayout(): number {
    const firstYear = this.getPayoutPlan().at(0);
    return firstYear?.amount ?? 0;
  }

  public getPayoutDuration(): PayoutDuration {
    return PAYOUT_DURATIONS.infinite;
  }

  public isLifelongPayout(): boolean {
    return true;
  }

  public isSavingsEngagement(): boolean {
    return false;
  }

  public hasChangeableProfile(): boolean {
    return false;
  }

  public getPayoutAgeRangeBoundary(): Boundary | undefined {
    if (
      !isTypeNumber(this.prognosisParameters.minimumAgeWithdrawal) ||
      !isTypeNumber(this.prognosisParameters.maximumAgeWithdrawal)
    ) {
      return undefined;
    }

    return {
      floor: this.prognosisParameters.minimumAgeWithdrawal,
      ceil: this.prognosisParameters.maximumAgeWithdrawal,
    };
  }

  public hasFolketrygdPrognosis(): boolean {
    return !!(this.prognosisParameters?.hasPublicPensionEngagements || this.prognosisParameters?.earnedPensionRight);
  }

  private getFirstContract(
    contracts?: Graph.PublicPensionContract[],
  ): Graph.PublicPensionContract | Contract | undefined {
    return (contracts || this.getContracts()).at(0);
  }

  private getFirstContractProductNameFmsKey(contracts?: Graph.PublicPensionContract[]): string | undefined {
    const firstContract = this.getFirstContract(contracts);

    return firstContract?.productNameFmsKey ?? undefined;
  }

  private getFirstContractDescription(contracts?: Graph.PublicPensionContract[]): Observable<string> {
    const fmsService = this.getFmsService();
    const productNameFmsKey = this.getFirstContractProductNameFmsKey(contracts);

    return fmsService.translateAsync(`${productNameFmsKey}.description`, {
      logEmpty: false,
    });
  }

  private getFirstContractName(contracts?: Graph.PublicPensionContract[]): Observable<string> {
    const fmsService = this.getFmsService();
    const productNameFmsKey = this.getFirstContractProductNameFmsKey(contracts);

    return fmsService.translateAsync<string>(productNameFmsKey);
  }

  private getFirstContractPayer(contracts?: Graph.PublicPensionContract[]): Observable<string> {
    const fmsService = this.getFmsService();
    return fmsService.translateAsync<string>("publicPensionGraphEngagement.others").pipe(
      first(),
      map((othersLabel) => {
        const firstContract = this.getFirstContract(contracts);
        const isMultipleContracts = (contracts?.length ?? 0) > 1;
        const multipleContractsLabel = isMultipleContracts ? ` ${othersLabel}` : "";
        const employerName = firstContract?.employer?.name;

        return employerName + multipleContractsLabel;
      }),
    );
  }

  private getIsAfpPayoutAtAge(age?: number): boolean {
    if (!isTypeNumber(age) || age > 66) {
      return false;
    }
    return ([] as Graph.PayoutPlan[])
      .concat(toArrayOrEmpty(this.prognosis?.paymentPlanAfp62))
      .concat(toArrayOrEmpty(this.prognosis?.paymentPlanAfp65))
      .some((el) => el?.age === age && isTypeNumber(el.amount) && el?.amount > 0);
  }
}

export function mapStatusMessageToSimulationStatus(
  status: Graph.PublicPensionStatusMessage,
): EngagementSimulationStatus {
  type StatusType = "S" | "F" | "A" | "I";
  const mapStatusTypeToSeverity = (statusType: StatusType): Graph.SimulationSeverity => {
    switch (statusType) {
      case "F":
        return Graph.SimulationSeverity.SimulationError;
      case "S":
        return Graph.SimulationSeverity.NotAllowed;
      case "A":
        return Graph.SimulationSeverity.Notice;
      case "I":
      case null:
      case undefined:
      default:
        return Graph.SimulationSeverity.Ok; //TODO: can this be something else? The type should really come from the graph.
    }
  };

  const severity = mapStatusTypeToSeverity(status.messageType as StatusType);
  const key = `sharedSimulationMessages.${status.messageKey}`;
  return { severity, key };
}
