@if (!!item.header) {
  <app-engagement-list-initiatives-item-header [item]="item" [isNumberedList]="isNumberedList">
  </app-engagement-list-initiatives-item-header>
}

<div class="item-content">
  @if (isNumberedList) {
    <app-engagement-list-initiatives-item-number
      class="item-content-number"
      [number]="item.number"
      [hideNumber]="item.hideNumber"
    >
    </app-engagement-list-initiatives-item-number>
  }
  <app-engagement-list-initiatives-item-engagement
    class="item-content-engagement"
    [class.numbered-item]="isNumberedList"
    [title]="item.title"
    [description]="item.description"
    [iconSrc]="item.iconSrc"
    [fields]="item.fields"
  >
  </app-engagement-list-initiatives-item-engagement>
</div>
