<button mat-dialog-close>
  <i>close</i>
</button>

<h3 mat-dialog-title>{{ "holdingsDialog.title" | fms }}</h3>

<mat-dialog-content class="modal-dialog-content-container">
  <table [attr.aria-label]="'holdingsDialog.table.tableLabel' | fms">
    <tr>
      <th id="fond">{{ "holdingsDialog.table.column.fond" | fms }}</th>
      <th id="distribution">
        {{ "holdingsDialog.table.column.distribution" | fms }}
      </th>
    </tr>
    @for (holding of data.holdings; track holding.name) {
      <tr>
        <td>{{ holding.name }}</td>
        <td>{{ holding.actualDistribution | number: "1.0-2" }} %</td>
      </tr>
    }
  </table>
</mat-dialog-content>

@if (data.contractNumberForUrl) {
  <div mat-dialog-actions>
    <button
      stbButton="contained"
      cdkFocusInitial
      (click)="changeInvestmentButtonClick()"
      data-trackid="holdingsDialog-changeButton"
    >
      {{ "holdingsDialog.changeButton.text" | fms }}
    </button>
  </div>
}
