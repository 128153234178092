@if (dashboardCard) {
  <stb-card-medium
    [text]="dashboardCard.text"
    [fluid]="true"
    [iconPath]="dashboardCard.illustration"
    [trackId]="trackId"
    (click)="cardClick.emit(dashboardCard.navigation)"
  >
  </stb-card-medium>
}
