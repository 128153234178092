<div class="list-item-container">
  @if (isNumberedList) {
    <app-engagement-list-initiatives-item-number class="list-item-number" [number]="1" [hideNumber]="true">
    </app-engagement-list-initiatives-item-number>
  }

  <h2 class="list-item-header header-4">
    {{ item.header }}
  </h2>
</div>
