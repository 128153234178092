<ng-container *appLet="hasAnnualGrossIncome$ | async as hasSalary">
  <p class="bold">
    {{ "annualIncomeChangeLink.title" | fms }}
  </p>
  @if (hasSalary) {
    <p
      [innerHTML]="'annualIncomeChangeLink.htmlText' | fms: { args: { annualGrossIncome: annualGrossIncome$ | async } }"
    ></p>
  } @else {
    <stb-inline-alert
      width="fluid"
      type="warning"
      [innerHTML]="'annualIncomeChangeLink.missingSalary.htmlText' | fms"
    ></stb-inline-alert>
  }

  <div class="buttons-wrapper">
    @if ((isNotPublicContext$ | async) && isNotMinePengerPage) {
      <button
        stbButton="text"
        [attr.data-trackid]="hasSalary ? 'annualIncomeChangeLink-changeSalary' : 'annualIncomeChangeLink-addSalary'"
        (click)="openSalaryOnboarding()"
      >
        {{
          hasSalary
            ? ("annualIncomeChangeLink.linkText" | fms)
            : ("annualIncomeChangeLink.missingSalary.linkText" | fms)
        }}
      </button>
    }

    <ng-content></ng-content>
  </div>
</ng-container>
