<div [@stepNavigation]="stepAnimationState$ | async">
  @if (isCurrentStepCancelable$ | async) {
    <button mat-dialog-close (click)="close()">
      <i>close</i>
    </button>
  }
  <h3 mat-dialog-title>
    {{ currentStepTitle$ | async }}
  </h3>

  <mat-dialog-content #resetFocus tabindex="-1">
    <ng-container #content></ng-container>
  </mat-dialog-content>
</div>

@if (showActions$ | async) {
  <div mat-dialog-actions class="actions">
    @if (shouldShowPreviousButton()) {
      <button stbButton="outlined" class="action-button" (click)="onPrevious()" [disabled]="loading$ | async">
        {{ previousButtonText$ | async }}
      </button>
    }
    <button
      stbButton="contained"
      class="action-button"
      [disabled]="loading$ | async"
      [enableSpinner]="loading$ | async"
      (click)="onNext()"
    >
      {{ nextButtonText$ | async }}
    </button>
  </div>
}
