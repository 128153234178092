<ng-container *appLet="ofaVendors$ | async as ofaVendors">
  @if (ofaVendors?.length > 0) {
    <div class="ofa-container">
      <app-public-pension-ofa-data [ofaVendors]="ofaVendors"></app-public-pension-ofa-data>
    </div>
  }
</ng-container>

<div class="summary-table bottom-border" [class.summary-table-responsive]="responsive">
  @if (showSimulationParameters) {
    <div class="summary-row mobile-only">
      <div class="summary-label">
        {{ "engagement.label.totalPayout" | fms }}
      </div>
      <div class="summary-value">
        @if (!hasError) {
          {{ getTotalPayout() | currencyFormatterPipe: "start" }}
        } @else {
          <app-amount-unavailable></app-amount-unavailable>
        }
      </div>
    </div>
    <div class="summary-row mobile-only">
      <div class="summary-label">
        {{ "engagement.label.payoutDuration" | fms }}
      </div>
      <div class="summary-value">
        @if (!hasError) {
          {{ getDuration() }}
        } @else {
          <app-amount-unavailable></app-amount-unavailable>
        }
      </div>
    </div>
  }

  @if (!hasError) {
    @for (details of this.contractDetails$ | async; track details.key) {
      <div class="summary-row">
        <div class="summary-label">
          {{ details.key | fms }}
        </div>
        <div class="summary-value">
          @if (details.value) {
            {{ details.value }}
          } @else {
            <app-amount-unavailable></app-amount-unavailable>
          }
        </div>
      </div>
    }
  }
</div>

<app-contract-type-description [engagement]="engagement"></app-contract-type-description>

<div class="contracts" [class.active]="engagement.isActive()">
  <h3 class="stb-mb2">{{ "publicPensionEngagement.contractDetails" | fms }}</h3>
  @for (contract of engagement.getContracts(); track contract.id) {
    <app-public-pension-contract [contract]="contract" [engagement]="engagement"></app-public-pension-contract>
  }
</div>
