import { Injectable } from "@angular/core";
import { EngagementService } from "src/app/services/engagement.service";
import { AnyEngagement } from "src/app/services/engagements.service";
import { FmsService } from "src/app/services/fms.service";
import { getIsSimulationStatusOk } from "src/app/utils/engagement.utils";
import { ContractActionBuilder, ContractActionType, ExternalLink } from "../contract-actions.types";
import { getHasContractNumber, getIsBalanceAboveZero } from "../contract-actions.utils";

@Injectable()
export class ChangeSavingsProfileOrFundActionBuilder implements ContractActionBuilder {
  constructor(
    private readonly engagementService: EngagementService,
    private readonly fmsService: FmsService,
  ) {}

  public predicate(engagement: AnyEngagement): boolean {
    return (
      getIsSimulationStatusOk(engagement) &&
      getHasContractNumber(engagement) &&
      engagement.hasChangeableProfile() &&
      getIsBalanceAboveZero(engagement)
    );
  }

  public build(engagement: AnyEngagement): ExternalLink {
    return {
      type: ContractActionType.External,
      linkLabel: this.getLinkLabel(),
      linkUrl: this.engagementService.getChangeSavingsProfileUrl(engagement),
      linkTrackId: this.getLinkTrackId(),
    };
  }

  private getLinkLabel(): string {
    return this.fmsService.instant("contractActions.changeSavingsProfileOrFund.linkLabel");
  }

  private getLinkTrackId(): string {
    return "ChangeSavingsProfileOrFundAction";
  }
}
