import { EngagementSimulationStatus } from "src/app/models/pension.model";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { pruneProps } from "src/app/utils/object";
import { Nullable } from "src/app/utils/utils";
import { GenericGraphEngagement } from "./generic-graph-engagement.model";

export abstract class GraphEngagement<C, TPrognosisParams> extends GenericGraphEngagement<
  C,
  Graph.Prognosis,
  TPrognosisParams
> {
  public constructor(contract: C, prognosis?: Graph.Prognosis) {
    super(contract, prognosis);
  }

  public getPayoutPlan(): Graph.PayoutPlan[] {
    return (this.prognosis?.payoutPlan ?? []) as Graph.PayoutPlan[];
  }

  public getFirstYearPayout(): Nullable<number> {
    const firstYear = this.getPayoutPlan().at(0);
    return firstYear?.amount ?? 0;
  }

  public getSimulationStatus(): EngagementSimulationStatus[] {
    return ([] as Graph.SimulationStatus[]).concat(this.prognosis?.simulationStatus ?? {}).map(getSimulationStatus);
  }

  public getPayoutFromAge(): number | undefined {
    return this.getPayoutPlan()[0]?.age || undefined;
  }

  public getPayoutToAge(): number | undefined {
    return this.getPayoutPlan().at(-1)?.age || undefined;
  }

  public isLifelongPayout(): boolean {
    // The duration comparison is a workaround while savings-graphql is returning lifeLong: null.
    // Workaround can be removed after the API has changed its behavior to correctly represent
    // the response from Norsk Pensjon.
    const duration = this.getPayoutDuration();

    return !!this?.prognosis?.lifeLong || duration?.years === Infinity;
  }
}

export function getSimulationStatus(simulationStatus: Nullable<Graph.SimulationStatus>): EngagementSimulationStatus {
  const severity = mapSimulationStatusSeverity(simulationStatus?.severity);
  const key = getSimulationStatusKeyOrUndefined(simulationStatus?.messageKey);

  return pruneProps({
    key,
    severity,
  });
}

function mapSimulationStatusSeverity(severity: Nullable<Graph.SimulationSeverity>): Graph.SimulationSeverity {
  return severity || Graph.SimulationSeverity.Ok;
}

function getSimulationStatusKeyOrUndefined(key: Nullable<string>): string | undefined {
  return key?.replace(/^(.*~)?(sharedSimulationMessages\.)?/, "sharedSimulationMessages.") ?? undefined;
}
