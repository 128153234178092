import { Injectable } from "@angular/core";
import { ApolloQueryResult } from "@apollo/client/core";
import { gql } from "apollo-angular";
import { DocumentNode } from "graphql";
import { Observable, of, shareReplay } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Contract as PublicPensionContract } from "src/app/models/engagements/public-pension-engagement.model";
import { ResponseHeaders } from "src/app/modules/graphql-clients/local-schema/response-headers";
import { SavingsGraphqlClientService } from "src/app/modules/graphql-clients/services/savings-graph-client.service";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { PrognosisQueryResult } from "src/app/services/prognoses-services/abstract-prognosis-fetch.service";
import { getIsNotNullable } from "src/app/utils/utils";
import { PublicPensionContractStatus } from "../prognoses-services/public-pension.service.vars";
import { ALL_AGE_COVERAGE_FIELDS_FRAGMENT } from "./graphql-query-fragments";

export interface GetPublicPensionPrognosisQuery extends Graph.Query {
  headers?: ResponseHeaders;
}

export const GET_PUBLIC_PENSION_CONTRACTS_QUERY = gql`
  query getPublicPensionContractsQuery($input: QueryInput) {
    savingsEngagement(input: $input) {
      publicPensionContracts {
        id
        contractNumber
        employer {
          name
          organizationNumber
        }
        status
        productNameFmsKey
        coverages {
          ageCoverages {
            ...AllAgeCoverageFields
          }
          conditionalAgeCoverages {
            ...AllAgeCoverageFields
          }
        }
        contractNavigation {
          contractDetailsLink
        }
      }
    }
  }
  ${ALL_AGE_COVERAGE_FIELDS_FRAGMENT}
`;

export const GET_PUBLIC_PENSION_PROGNOSIS_QUERY = gql`
  query getPublicPensionPrognosisQuery($input: PublicPensionPrognosisInput) {
    headers @client {
      correlationId
    }
    publicPensionPrognosis(input: $input) {
      id
      currentWithdrawalDate
      paymentPlanAfp62 {
        age
        amount
      }
      paymentPlanAfp65 {
        age
        amount
      }
      paymentPlanFolketrygd {
        age
        amount
      }
      paymentPlanPension {
        age
        amount
      }
      paymentPlanSalary {
        age
        amount
      }
      statusMessages {
        messageKey
        messageText
        messageType
      }
    }
  }
`;

export const CONTRACTS_STATUS_QUERY = gql`
  query contractsStatusQuery($input: QueryInput) {
    savingsEngagement(input: $input) {
      publicPensionContracts {
        status
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class PublicPensionQueriesService {
  constructor(private readonly savingsGraphqlClient: SavingsGraphqlClientService) {}

  public getPublicPensionContracts(): Observable<PublicPensionContract[] | null> {
    const query$ = this.query(GET_PUBLIC_PENSION_CONTRACTS_QUERY).pipe(map(queryToPublicPensionContracts));

    return this.subscribeOrFallback(query$, of(null)).pipe(shareReplay(1));
  }

  public getPublicPensionPrognosis(
    variables: Graph.QueryPublicPensionPrognosisArgs,
  ): Observable<PrognosisQueryResult<Graph.PublicPensionPrognosis[]>> {
    const query$ = this.query(GET_PUBLIC_PENSION_PROGNOSIS_QUERY, variables).pipe(map(queryToPublicPensionPrognosis));

    return this.subscribeOrFallback(query$, of({ data: [] }));
  }

  public isAnyPublicPensionContractActive(): Observable<boolean> {
    const query$ = this.query(CONTRACTS_STATUS_QUERY).pipe(
      map(queryToPublicPensionContracts),
      map(isAnyPublicPensionContractActive),
    );

    return this.subscribeOrFallback(query$, of(false));
  }

  private query(
    query: DocumentNode,
    variables: Graph.QueryPublicPensionPrognosisArgs | Graph.QuerySavingsEngagementArgs = {},
  ): Observable<ApolloQueryResult<Graph.Query>> {
    return this.savingsGraphqlClient.query({
      query,
      variables,
    });
  }

  private subscribeOrFallback<T>(source$: Observable<T>, fallback$: Observable<T>): Observable<T> {
    return source$.pipe(catchError(() => fallback$));
  }
}

function queryToPublicPensionContracts(query: ApolloQueryResult<Graph.Query>): PublicPensionContract[] | null {
  return query.data?.savingsEngagement?.publicPensionContracts ?? null;
}

function queryToPublicPensionPrognosis(
  query: ApolloQueryResult<GetPublicPensionPrognosisQuery>,
): PrognosisQueryResult<Graph.PublicPensionPrognosis[]> {
  return {
    headers: query.data?.headers,
    data: [query.data?.publicPensionPrognosis].filter(getIsNotNullable),
  };
}

function isAnyPublicPensionContractActive(contracts: PublicPensionContract[] | null): boolean {
  return contracts?.some((contract) => contract?.status === PublicPensionContractStatus.Active) ?? false;
}
