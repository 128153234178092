<div class="container">
  <app-breadcrumb-back-button [to]="frontPageRoute" trackId="managePkbPages-back"></app-breadcrumb-back-button>

  <app-page-title [text]="'managePkbPages.pageTitle' | fms"></app-page-title>

  <div class="content-container">
    <section class="content">
      <img class="image" src="https://assets.storebrand.no/icons/documents-lock.svg" alt />

      <p>{{ "managePkbPages.intro" | fms }}</p>

      <ul>
        @for (point of points$ | async; track point) {
          <li>{{ point }}</li>
        }
      </ul>

      <button stbButton="contained" data-trackid="managePkbPages-cta" role="link" (click)="openNorskPensjon()">
        {{ "managePkbPages.buttonText" | fms }}
      </button>
    </section>

    <section class="faq">
      <stb-accordion>
        @for (item of faq$ | async; track item) {
          <stb-accordion-item [headerText]="item.question">
            <div [innerHTML]="item.answer"></div>
          </stb-accordion-item>
        }
      </stb-accordion>
    </section>
  </div>
</div>
