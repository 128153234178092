<app-engagement-list-initiatives-header [headers]="getHeaders()"> </app-engagement-list-initiatives-header>

<div>
  @for (item of getVisibleItems(); track item.title) {
    <app-engagement-list-initiatives-item class="list-item" [item]="item" [isNumberedList]="isNumberedList">
    </app-engagement-list-initiatives-item>
  }
</div>

<app-engagement-list-initiatives-expander
  [showMoreButton]="getShouldShowAllButton()"
  [showLessButton]="getShouldShowLessButton()"
  (toggleShowLessMoreButton)="toggleShouldShowAllItems()"
>
</app-engagement-list-initiatives-expander>
