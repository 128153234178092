<ng-container *appLet="stepper$ | async as stepper" [formGroup]="offentligEngagementForm">
  <button mat-dialog-close [attr.data-traclid]="'offentligTjenestepensjonEngagementDialog-closeButton' + stepper">
    <i>close</i>
  </button>
  <h3 mat-dialog-title>{{ getWindowTitle() | async }}</h3>

  <mat-dialog-content>
    @if (stepper === 0) {
      <p [innerHtml]="getIntroText() | async"></p>
    } @else if (stepper === 1) {
      <ng-container formGroupName="firstGroup">
        <stb-radio-buttons
          [group]="hasEnoughTimeInPublicSectorRadioButtons$ | async"
          [legend]="'offentligTjenestepensjonEngagement.form.hasEnoughTimeInPublicSector.legend' | fms"
          [message]="'offentligTjenestepensjonEngagement.form.hasEnoughTimeInPublicSector.message' | fms"
          [warning]="hasEnoughTimeInPublicSector.invalid && hasEnoughTimeInPublicSector.touched"
          (valueChanged)="sethasEnoughTimeInPublicSectorControl($event)"
        >
        </stb-radio-buttons>
        @if (hasEnoughTimeInPublicSector.valid && hasEnoughTimeInPublicSector.value === false) {
          <stb-alert
            [heading]="'offentligTjenestepensjonEngagement.form.hasEnoughTimeInPublicSector.disclaimer.heading' | fms"
            [text]="'offentligTjenestepensjonEngagement.form.hasEnoughTimeInPublicSector.disclaimer.text' | fms"
            type="info"
            width="fluid"
          ></stb-alert>
          <mat-checkbox
            formControlName="removeSuggestionToAdd"
            [attr.data-trackid]="
              'offentligTjenestepensjonEngagementDialog-removeSuggestionToAdd-' + removeSuggestionToAdd.value
            "
            >{{ "offentligTjenestepensjonEngagement.form.removeSuggestionToAdd" | fms }}
          </mat-checkbox>
        }
      </ng-container>
    } @else if (stepper === 2) {
      <ng-container formGroupName="secondGroup">
        <span>
          {{ "offentligTjenestepensjonEngagement.form.payoutDetailsIntro" | fms }}
        </span>
        <div class="forms-wrapper">
          <stb-form
            size="large"
            [label]="'offentligTjenestepensjonEngagement.form.annualAmount.label' | fms"
            [hint]="'offentligTjenestepensjonEngagement.form.annualAmount.hint' | fms"
            [error]="annualAmount.touched && (annualAmount.hasError('required') || annualAmount.hasError('pattern'))"
            [errorMessage]="
              (annualAmount.hasError('pattern')
                ? 'offentligTjenestepensjonEngagement.form.annualAmount.validation.zero'
                : 'offentligTjenestepensjonEngagement.form.annualAmount.validation.required'
              ) | fms
            "
          >
            <input appCurrencyMask formControlName="annualAmount" id="annualAmount" type="tel" />
          </stb-form>
          <div class="small-margin-bottom">
            <stb-form
              size="large"
              [label]="'offentligTjenestepensjonEngagement.form.fromAge.label' | fms"
              [errorMessage]="'offentligTjenestepensjonEngagement.form.fromAge.validation' | fms"
              [error]="fromAge.touched && fromAge.hasError('required')"
            >
              <select formControlName="fromAge" id="fromAge">
                @for (fromAge of ageRange$ | async; track fromAge) {
                  <option [value]="fromAge">
                    {{ fromAge | year }}
                  </option>
                }
              </select>
            </stb-form>
          </div>
        </div>
        <stb-inline-alert [label]="'offentligTjenestepensjonEngagement.inlineAlert' | fms" width="fluid" type="info">
        </stb-inline-alert>
      </ng-container>
    } @else if (stepper === 3) {
      <ng-container formGroupName="thirdGroup">
        <div *appLet="getProviderName() | async as providerName">
          @if (providerName) {
            <label for="prefilled-provider">
              {{ "offentligTjenestepensjonEngagement.form.title.label" | fms }}
            </label>
            <div class="title-prefilled" id="prefilled-provider">
              {{ providerName }}
            </div>
          } @else if (!providerName) {
            <stb-form
              size="large"
              [label]="'offentligTjenestepensjonEngagement.form.title.label' | fms"
              [hint]="'offentligTjenestepensjonEngagement.form.title.hint' | fms"
              [error]="title.touched && title.hasError('required')"
              [errorMessage]="'offentligTjenestepensjonEngagement.form.title.validation' | fms"
            >
              <input formControlName="title" id="title" type="text" />
            </stb-form>
          }
        </div>
        <stb-form
          size="large"
          [label]="'offentligTjenestepensjonEngagement.form.payer.label' | fms"
          [hint]="'offentligTjenestepensjonEngagement.form.payer.hint' | fms"
          [error]="payer.touched && payer.hasError('required')"
          [errorMessage]="'offentligTjenestepensjonEngagement.form.payer.validation' | fms"
        >
          <input formControlName="payer" id="payer" type="text" />
        </stb-form>
      </ng-container>
    } @else if (stepper === 4) {
      <app-offentlig-tjenestepensjon-engagement-receipt></app-offentlig-tjenestepensjon-engagement-receipt>
    }
  </mat-dialog-content>

  <mat-dialog-actions>
    @if (engagementSteps[stepper].backButtonText) {
      <button
        stbButton="outlined"
        type="button"
        (click)="onBackButton()"
        [attr.data-trackid]="'offentligTjenestepensjonEngagementDialog-backButton' + stepper"
      >
        {{ engagementSteps[stepper].backButtonText }}
      </button>
    }

    @if (hasEnoughTimeInPublicSector.value === false && stepper === 1) {
      <button
        stbButton="contained"
        (click)="exitButtonAfterFalseOccupation()"
        type="button"
        [attr.data-trackid]="'offentligTjenestepensjonEngagementDialog-exitButton-' + stepper"
      >
        {{ "offentligTjenestepensjonEngagement.exitButton" | fms }}
      </button>
    } @else {
      <button
        stbButton="contained"
        type="button"
        [attr.data-trackid]="'offentligTjenestepensjonEngagementDialog-nextButton' + stepper"
        [enableSpinner]="isSubmitting$ | async"
        (click)="onNextButton()"
      >
        @if (hasInjectedEngagement() && this.isFinalStep()) {
          {{ "addEngagement.saveAfterEditButton.text" | fms }}
        } @else {
          {{ engagementSteps[stepper].nextButtonText }}
        }
      </button>
    }
  </mat-dialog-actions>
</ng-container>
