import { Injectable } from "@angular/core";
import { gql } from "apollo-angular";
import { map, mergeMap, Observable } from "rxjs";
import { ContractTypeOf } from "src/app/models/engagements/generic-engagement.model";
import {
  EpkEngagement,
  EpkFleksibelEngagement,
} from "src/app/models/engagements/savings-and-pension/epk-engagement.model";
import { FmiEngagement } from "src/app/models/engagements/savings-and-pension/fmi-engagement.model";
import { HybridMedInvesteringsvalgEngagement } from "src/app/models/engagements/savings-and-pension/hybrid-engagement.model";
import { AbstractLinkEngagement } from "src/app/models/engagements/savings-and-pension/link-engagement.model";
import {
  EpkFleksibelPkbEngagement,
  PkbEngagement,
} from "src/app/models/engagements/savings-and-pension/pkb-engagement.model";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { AnySavingsAndPensionEngagement } from "src/app/services/prognoses-services/savings-and-pension.service";
import { getIsNullable } from "src/app/utils/utils";
import { SavingsGraphqlClientService } from "../../graphql-clients/services/savings-graph-client.service";
import { getApolloResponseData } from "../../graphql-clients/utils/apollo-base-wrapper";
import { MarketValueChartData } from "../components/charts/market-value-chart/market-value-chart.component";
import { min } from "date-fns";

interface MarketValueContract {
  marketValues: {
    date: Graph.Scalars["ISO8601Date"]["output"];
    marketValue: {
      value: Graph.Scalars["Float"]["output"];
    };
    mainCapital: {
      value: Graph.Scalars["Float"]["output"];
    };
  }[];
}

interface SavingsEngagementQuery {
  savingsEngagement: Graph.SavingsEngagement;
}

const MARKET_VALUES = gql`
  fragment MarketValues on PortfolioInsightMarketValue {
    date
    mainCapital {
      value
    }
    marketValue {
      value
    }
  }
`;

const KEY_VALUES = gql`
  fragment KeyValues on KeyValues {
    annualAverageReturnPct
    totalReturnPct
  }
`;

export const GET_MARKET_VALUES_QUERY = gql`
  query getMarketValuesQuery($ids: [ID]) {
    savingsEngagement {
      epkContracts(ids: $ids) {
        marketValues {
          ...MarketValues
        }
        keyValues {
          ...KeyValues
        }
      }
      pkbContracts(ids: $ids) {
        marketValues {
          ...MarketValues
        }
        keyValues {
          ...KeyValues
        }
      }
      fmiContracts(ids: $ids) {
        marketValues {
          ...MarketValues
        }
        keyValues {
          ...KeyValues
        }
      }
      ekstrapensjonContracts(ids: $ids) {
        marketValues {
          ...MarketValues
        }
        keyValues {
          ...KeyValues
        }
      }
      ekstrapensjonEmploymentContracts(ids: $ids) {
        marketValues {
          ...MarketValues
        }
        keyValues {
          ...KeyValues
        }
      }
      epkFleksibelContracts(ids: $ids) {
        marketValues {
          ...MarketValues
        }
        keyValues {
          ...KeyValues
        }
      }
      epkFleksibelPkbContracts(ids: $ids) {
        marketValues {
          ...MarketValues
        }
        keyValues {
          ...KeyValues
        }
      }
      hybridMedInvesteringsvalgContracts(ids: $ids) {
        marketValues {
          ...MarketValues
        }
        keyValues {
          ...KeyValues
        }
      }
      garantiContracts(ids: $ids) {
        marketValues {
          ...MarketValues
        }
        keyValues {
          ...KeyValues
        }
      }
      ipaLinkContracts(ids: $ids) {
        marketValues {
          ...MarketValues
        }
        keyValues {
          ...KeyValues
        }
      }
      ipoContracts(ids: $ids) {
        marketValues {
          ...MarketValues
        }
        keyValues {
          ...KeyValues
        }
      }
      ipsContracts(ids: $ids) {
        marketValues {
          ...MarketValues
        }
        keyValues {
          ...KeyValues
        }
      }
      livrenteLinkContracts(ids: $ids) {
        marketValues {
          ...MarketValues
        }
        keyValues {
          ...KeyValues
        }
      }
      fondskontoLinkContracts(ids: $ids) {
        marketValues {
          ...MarketValues
        }
        keyValues {
          ...KeyValues
        }
      }
    }
  }
  ${MARKET_VALUES}
  ${KEY_VALUES}
`;

@Injectable()
export class FetchMarketDataService {
  constructor(private readonly savingsGraphqlClient: SavingsGraphqlClientService) {}

  public fetchMarketValueChartData(
    marketValuesContract: ContractTypeOf<AnySavingsAndPensionEngagement>,
  ): MarketValueChartData {
    const marketValues = (marketValuesContract as MarketValueContract)?.marketValues ?? [];

    const marketValueData = marketValues.map((insight) => [
      new Date(insight.date).getTime(),
      insight.marketValue.value,
    ]);

    const paidInData = marketValues.map((insight) => [new Date(insight.date).getTime(), insight.mainCapital.value]);

    return { marketValueData, paidInData };
  }

  public fetchAverageAnnualReturnPct(marketValuesContract: ContractTypeOf<EngagementWithMarketAndKeyValues>): number {
    const annualAverageReturnPct = marketValuesContract?.keyValues?.annualAverageReturnPct;

    if (getIsNullable(annualAverageReturnPct)) {
      throw new Error("AnnualAverageReturnPct did not exist");
    }
    return annualAverageReturnPct;
  }

  public fetchFirstMarketValueDate(marketValuesContract: ContractTypeOf<AnySavingsAndPensionEngagement>): Date {
    try {
      const marketValues = (marketValuesContract as MarketValueContract).marketValues;
      const dates = marketValues.map(({ date }) => new Date(date));
      const getFirstDate = min(dates);

      return new Date(getFirstDate);
    } catch (e: unknown) {
      throw new Error("First market value date was not found. " + e);
    }
  }

  public fetchTotalReturnPctDate(marketValuesContract: ContractTypeOf<EngagementWithMarketAndKeyValues>): number {
    const totalReturnPct = marketValuesContract.keyValues?.totalReturnPct;
    if (getIsNullable(totalReturnPct)) {
      throw new Error("totalReturnPct did not exist");
    }
    return totalReturnPct;
  }

  public fetchMarketValues<T extends AnySavingsAndPensionEngagement>(engagement: T): Observable<ContractTypeOf<T>> {
    const id = engagement.getId();

    return this.savingsGraphqlClient
      .query<SavingsEngagementQuery>({
        query: GET_MARKET_VALUES_QUERY,
        variables: { ids: [id] },
      })
      .pipe(
        mergeMap(getApolloResponseData),
        map((contractsDetails) => {
          return Object.values(contractsDetails.savingsEngagement)
            .filter((contracts) => Array.isArray(contracts) && contracts.length > 0)
            .flat()
            .at(0);
        }),
        map((details) => ({
          ...engagement.contract,
          ...details,
        })),
      );
  }
}

export type EngagementWithMarketAndKeyValues =
  | EpkEngagement
  | PkbEngagement
  | FmiEngagement
  | EpkFleksibelEngagement
  | EpkFleksibelPkbEngagement
  | HybridMedInvesteringsvalgEngagement
  | AbstractLinkEngagement;

const engagementWithMarketAndKeyValues = [
  EpkEngagement,
  PkbEngagement,
  FmiEngagement,
  EpkFleksibelEngagement,
  EpkFleksibelPkbEngagement,
  HybridMedInvesteringsvalgEngagement,
  AbstractLinkEngagement,
];

export function isEngagementWithMarketAndKeyValues(
  engagement: AnySavingsAndPensionEngagement,
): engagement is EngagementWithMarketAndKeyValues {
  return engagementWithMarketAndKeyValues.some((e) => engagement instanceof e);
}
