<figure class="chart-component">
  @if (showSkeletons$ | async) {
    <app-chart-skeleton [lines]="skeletons" [width]="skeletonsWidth" [style.height]="height + 'px'">
    </app-chart-skeleton>
  } @else {
    <highcharts-chart
      [Highcharts]="Highcharts"
      constructorType="chart"
      (chartInstance)="emitChartInstance($event)"
      [options]="optionsWithSeries$ | async"
      [oneToOne]="oneToOne"
    ></highcharts-chart>
    @if (showLifelongText) {
      <p class="chartLifelongText">{{ "chart.lifelong.text" | fms }}</p>
    }
  }
</figure>
