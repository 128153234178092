@if (isTruthy((renderTrigger$ | async)!)) {
  <ng-content></ng-content>
} @else {
  @for (line of [].constructor(lines); track line) {
    <div class="text-skeleton" [class.multiple]="isMultipleLines()" [class]="type" [style.width]="getRandomWidth()">
      <!-- &nbsp; to ensure same height as parent text element -->
      &nbsp;
    </div>
  }
}
