<section>
  <h2>{{ "dashboard.linkList.title" | fms }}</h2>
  <ul>
    @for (link of linkList$ | async; track link.key) {
      <stb-link-list
        [index]="link.key"
        [text]="link.text"
        (linkClick)="onLinkClick(link.url)"
        [attr.data-trackid]="link.trackId"
      >
      </stb-link-list>
    }
  </ul>
</section>
