import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Observable, catchError, filter, map, of, switchMap, shareReplay } from "rxjs";
import { CurrencyFormatterPipe } from "src/app/modules/shared/pipes/currency-formatter.pipe";
import { ContractDetailsService } from "src/app/modules/shared/services/contract-details.service";
import { FetchTotalReturnService } from "src/app/modules/shared/services/fetch-total-return-service";
import { AnyEngagement } from "src/app/services/engagements.service";
import { FmsService } from "src/app/services/fms.service";
import { isSavingsAndPensionEngagement } from "src/app/utils/engagement.typeguards";
import { ReplayStore } from "src/app/utils/rxjs/store";
import { Nullable, getIsNotNullable, getIsNullable } from "src/app/utils/utils";
import { getBalanceFromContractOrContractDetails } from "../../../engagement-details/engagement-details-common/engagement-details-common.utils";
import { take } from "rxjs/operators";

@Component({
  selector: "app-contract-balance-and-total-return",
  templateUrl: "./contract-balance-and-total-return.component.html",
  styleUrls: ["./contract-balance-and-total-return.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractBalanceAndTotalReturnComponent {
  public readonly engagement$ = new ReplayStore<AnyEngagement>();

  public readonly balance$ = this.getBalance();
  public readonly hasBalance$ = this.balance$.pipe(map(getIsNotNullable));

  public readonly totalReturnLabel$: Observable<string>;
  public readonly formattedTotalReturn$: Observable<Nullable<string | number>>;
  public readonly hasTotalReturn$: Observable<boolean>;

  constructor(
    private readonly fetchTotalReturnService: FetchTotalReturnService,
    private readonly currencyFormatterPipe: CurrencyFormatterPipe,
    private readonly fmsService: FmsService,
    private readonly contractDetailsService: ContractDetailsService,
  ) {
    const totalReturn$ = this.engagement$.pipe(
      switchMap((engagement) =>
        isSavingsAndPensionEngagement(engagement)
          ? this.fetchTotalReturnService.fetchTotalReturn(engagement)
          : of(undefined),
      ),
      take(1),
      shareReplay(1),
      catchError(() => of(undefined)),
    );

    this.formattedTotalReturn$ = totalReturn$.pipe(
      map((totalReturn) => {
        if (getIsNullable(totalReturn)) {
          return undefined;
        }

        const formattedTotalReturn = this.currencyFormatterPipe.transform(totalReturn, "end");
        if (totalReturn === 0) {
          return formattedTotalReturn;
        }

        const prefix = totalReturn > 0 ? "+" : "";
        return `${prefix} ${formattedTotalReturn}`;
      }),
    );
    this.hasTotalReturn$ = totalReturn$.pipe(map(getIsNotNullable));

    this.totalReturnLabel$ = totalReturn$.pipe(
      map((totalReturn) => totalReturn ?? 0),
      map(getTotalReturnFmsKey),
      switchMap((fmsKey) => this.fmsService.translateAsync<string>(fmsKey)),
    );
  }

  @Input()
  public set engagement(anyEngagement: AnyEngagement) {
    this.engagement$.next(anyEngagement);
  }

  private getBalance(): Observable<Nullable<number>> {
    const contractDetails$ = this.engagement$.pipe(
      filter(isSavingsAndPensionEngagement),
      switchMap((engagement) => this.contractDetailsService.fetchContractDetails(engagement)),
    );

    return getBalanceFromContractOrContractDetails(this.engagement$, contractDetails$).pipe(shareReplay(1));
  }
}

function getTotalReturnFmsKey(totalReturn: number): string {
  return totalReturn >= 0 ? "engagement.label.totalReturn.profit" : "engagement.label.totalReturn.loss";
}
