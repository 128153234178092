@if (canChangePublicPensionWorkingPercentage$ | async) {
  <div class="content">
    <ng-container *appLet="disableFormfields$ | async as disableFormfields">
      <div>
        <h4>
          {{ "simulationAge.partTimeToggle.label" | fms }}
        </h4>
        <app-yes-no-radio-buttons
          [value$]="postPensionPartTimeEnable$"
          (change)="onEnableChange($event)"
          [disabled]="disableFormfields"
        ></app-yes-no-radio-buttons>
      </div>
      @if (postPensionPartTimeEnable$ | async) {
        <div class="parameters">
          <app-part-time-percent-form-field
            class="parameter"
            [disabled]="disableFormfields"
          ></app-part-time-percent-form-field>
          @if (showPublicPensionWithdrawalPercentFormField$ | async) {
            <app-public-pension-withdrawal-percentage-form-field
              class="parameter"
              [disabled]="disableFormfields"
            ></app-public-pension-withdrawal-percentage-form-field>
          }
          @if (publicPensionEngagementSimParams$ | async; as publicPensionEngagementSimParams) {
            <app-part-time-end-age-form-field
              class="parameter"
              [engagement]="publicPensionEngagement$ | async"
              [engagementSimParams]="publicPensionEngagementSimParams"
              [disabled]="disableFormfields"
            ></app-part-time-end-age-form-field>
          }
          @if (showExpectedFutureIncomeFormField$ | async) {
            <app-post-pension-future-salary-form-field
              class="parameter"
              [disabled]="disableFormfields"
            ></app-post-pension-future-salary-form-field>
          }
        </div>
      }
    </ng-container>
  </div>
}
