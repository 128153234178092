<div class="summary-table" [class.summary-table-responsive]="responsive">
  @if (holdings && holdings.length > 0) {
    <div class="summary-row">
      <div class="summary-label">
        {{ "engagement.label.savingProfileFund" | fms }}
      </div>
      <div class="summary-value" data-hj-suppress>
        <a data-trackid="engagementDetail-savingProfileFondLink" (click)="openDialogWithFunds()">
          @if (holdings.length === 1) {
            {{ holdings[0].name }}
          } @else {
            {{ "engagement.label.savingProfileFund.multipleHoldings" | fms }}
          }
        </a>
      </div>
    </div>
  }

  @if (yearlyDeposit) {
    <div class="summary-row">
      <div class="summary-label">
        {{ "engagement.label.savingsRate.yearly" | fms }}
      </div>
      <div class="summary-value">
        {{ yearlyDeposit | currencyFormatterPipe: "start" }}
      </div>
    </div>
  }

  @if (contractNumber) {
    <div class="summary-row">
      <div class="summary-label">
        {{ "engagement.label.contractNumber" | fms }}
      </div>
      <div class="summary-value" data-hj-suppress>
        {{ contractNumber }}
      </div>
    </div>
  }
</div>

<ng-template #amountUnavailable>
  <app-amount-unavailable></app-amount-unavailable>
</ng-template>
