import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "app-engagement-loading-skeleton",
  standalone: true,
  imports: [],
  templateUrl: "./engagement-loading-skeleton.component.html",
  styleUrl: "./engagement-loading-skeleton.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngagementLoadingSkeletonComponent {
  @Input()
  public isContract: boolean = false;
}
