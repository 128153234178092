<div class="tiny-title">{{ "chartLegend.title" | fms }}</div>
<div class="legend-wrapper">
  <div class="legend-item">
    <div class="color-square symbol" [style.background]="incomeColumnColor"></div>
    <span>{{ "chartLegend.annualGrossIncome" | fms }}</span>
  </div>

  <div class="legend-item">
    <img class="symbol" style="height: 12px" [src]="'chartLegend.startPayoutAge.image' | fms" alt="" />
    <span>{{ "chartLegend.startPayoutAge" | fms }}</span>
  </div>

  @if (showRangedNeedsLegend) {
    <div class="legend-item">
      <div class="color-square symbol" [style.background]="rangedNeedsColumnFill"></div>
      <span>{{ "chartLegend.pension" | fms }}</span>
    </div>
    <div class="legend-item">
      <div class="needs-range-symbol symbol" [style.border-color]="rangedNeedsAreaStroke">
        <svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%">
          <defs>
            <pattern id="strokes" patternUnits="userSpaceOnUse" width="10" height="10">
              <path
                [attr.stroke]="rangedNeedsAreaFill"
                stroke-width="4"
                d="M 10 0 L 0 10 M 1 -1 L -1 1 M 11 9 L 9 11"
              />
            </pattern>
          </defs>
          <rect width="100%" height="100%" fill="url(#strokes)" />
        </svg>
      </div>
      <span>{{ "chartLegend.rangedNeeds" | fms }}</span>
    </div>
  }
</div>
