@if (footerRows.length) {
  <div class="footer footer-top-border" [class.active]="engagement.isActive()">
    @for (footerRow of footerRows; track footerRow.icon) {
      <div class="footer-row">
        <img [src]="footerRow.icon" alt />
        <span [innerHTML]="footerRow.text"></span>
      </div>
    }
  </div>
}
