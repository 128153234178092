<ng-container *appLet="isLoaded$ | async as isLoaded">
  @if (showInBox) {
    <div class="user-summary-box full-page-width-limiter">
      @if (!isLoaded) {
        <div class="text-wrapper">
          <app-text-skeleton type="green"></app-text-skeleton>
          <app-text-skeleton type="green"></app-text-skeleton>
          <app-text-skeleton type="green"></app-text-skeleton>
          <app-text-skeleton type="green"></app-text-skeleton>
          <app-text-skeleton type="green"></app-text-skeleton>
          <app-text-skeleton type="green"></app-text-skeleton>
        </div>
      }
      @if (isLoaded) {
        <div class="text-wrapper">
          @if (userAge$ | async; as age) {
            <div class="summary-row">
              <p [innerHTML]="'userSummaryBox.age' | fms: { args: { age } }" data-hj-suppress></p>
            </div>
          }
          @if (pensionAgeArgs$ | async; as pensionAgeArgs) {
            <div class="summary-row">
              <p
                [innerHTML]="'userSummaryBox.timeUntilPensionAge' | fms: { args: pensionAgeArgs }"
                data-hj-suppress
              ></p>
            </div>
          }
          <div class="summary-row">
            <p [innerHTML]="numberOfEngagementsText$ | async"></p>
          </div>
          <div class="summary-row">
            <p [innerHTML]="hasSavings$ | async"></p>
          </div>
          @if (isQualifiedForSmartAccount$ | async) {
            <div class="summary-row">
              <p [innerHTML]="'userSummaryBox.smartAccount' | fms"></p>
            </div>
          }
          @if (hasAnnualSalary$ | async) {
            <div class="summary-row">
              <p [innerHTML]="annualSalaryText$ | async" data-hj-suppress></p>
            </div>
          }
          @if (hasAfp$ | async) {
            <div class="summary-row">
              <p [innerHTML]="'userSummaryBox.afp' | fms" data-hj-suppress></p>
            </div>
          }
          @if (averagePercentOfSalay$ | async; as averagePercentOfSalay) {
            <div class="summary-row">
              <p [innerHTML]="'userSummaryBox.averagePercentOfSalary' | fms: { args: { averagePercentOfSalay } }"></p>
            </div>
          }
        </div>
      }
      <div class="img-wrapper">
        <img [src]="iconSrc" alt="" />
      </div>
    </div>
  } @else {
    @if (firstName$ | async; as firstName) {
      <div class="summary-column">
        <p
          [innerHTML]="'userSummary.firstName' | fms: { args: { firstName: firstName | formatName } }"
          data-hj-suppress
        ></p>
      </div>
    }
    @if (userAge$ | async; as age) {
      <div class="summary-column">
        <img [src]="'userSummary.age.iconSrc' | fms" alt />
        <p [innerHTML]="'userSummary.age' | fms: { args: { age } }" data-hj-suppress></p>
      </div>
    }
    @if (pensionAgeArgs$ | async; as pensionAgeArgs) {
      <div class="summary-column">
        <img [src]="'userSummary.timeUntilPensionAge.iconSrc' | fms" alt />
        @if (isLoaded) {
          <p [innerHTML]="'userSummary.timeUntilPensionAge' | fms: { args: pensionAgeArgs }" data-hj-suppress></p>
        }
        @if (!isLoaded) {
          <app-text-skeleton></app-text-skeleton>
        }
      </div>
    }
    <div class="summary-column">
      <img [src]="'userSummary.engagements.iconSrc' | fms" alt />
      @if (isLoaded) {
        <p [innerHTML]="numberOfEngagementsText$ | async"></p>
      }
      @if (!isLoaded) {
        <app-text-skeleton></app-text-skeleton>
      }
    </div>
    @if (isLongSinceLastStepVisit$ | async) {
      <div class="summary-column">
        <img [src]="'userSummary.lastStepVisit.iconSrc' | fms" alt />
        <p [innerHTML]="lastStepVisitText$ | async"></p>
      </div>
    }
    <div class="summary-column">
      <img [src]="'userSummary.hasActiveEngagement.iconSrc' | fms" alt />
      @if (isLoaded) {
        <p [innerHTML]="hasSavings$ | async"></p>
      }
      @if (!isLoaded) {
        <app-text-skeleton></app-text-skeleton>
      }
    </div>
    @if (isQualifiedForSmartAccount$ | async) {
      <div class="summary-column">
        <img [src]="'userSummary.smartAccount.iconSrc' | fms" alt />
        <p [innerHTML]="'userSummary.smartAccount' | fms"></p>
      </div>
    }
    @if (hasAnnualSalary$ | async) {
      <div class="summary-column">
        <img [src]="'userSummary.annualSalary.iconSrc' | fms" alt />
        <p [innerHTML]="annualSalaryText$ | async" data-hj-suppress></p>
      </div>
    }
    @if (hasAfp$ | async) {
      <div class="summary-column">
        <img [src]="'userSummary.afp.iconSrc' | fms" alt />
        <p [innerHTML]="'userSummary.afp' | fms" data-hj-suppress></p>
      </div>
    }
    @if (averagePercentOfSalay$ | async; as averagePercentOfSalay) {
      <div class="summary-column">
        <img [src]="'userSummary.averagePercentOfSalay.iconSrc' | fms" alt />
        <p [innerHTML]="'userSummary.averagePercentOfSalary' | fms: { args: { averagePercentOfSalay } }"></p>
      </div>
    }
  }
</ng-container>
