import { Injectable } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { firstValueFrom } from "rxjs";
import { LARGE_DIALOG_WINDOW_WIDTH } from "src/app/constants/technical.constants";
import { ExternalSavingsEngagement } from "src/app/models/engagements/external-savings-engagement.model";
import { OtherPensionEngagement } from "src/app/models/engagements/other-pension-engagement.model";
import { AnyEngagement } from "src/app/services/engagements.service";
import { FmsService } from "src/app/services/fms.service";
import { ExternalEngagementComponent } from "../../../components/add-engagement/external-engagement/external-engagement.component";
import { OffentligTjenestepensjonEngagementComponent } from "../../../components/add-engagement/offentlig-tjenestepensjon-engagement/offentlig-tjenestepensjon-engagement.component";
import { OtherPensionEngagementComponent } from "../../../components/add-engagement/other-pension-engagement/other-pension-engagement.component";
import { ButtonLink, ContractActionBuilder, ContractActionType } from "../contract-actions.types";

@Injectable()
export class EditContractActionBuilder implements ContractActionBuilder {
  constructor(
    private readonly dialog: MatDialog,
    private readonly fmsService: FmsService,
  ) {}

  public predicate(engagement: AnyEngagement): boolean {
    return engagement instanceof ExternalSavingsEngagement || engagement instanceof OtherPensionEngagement;
  }

  public async build(engagement: AnyEngagement): Promise<ButtonLink> {
    const [linkLabel, linkTrackId] = await Promise.all([this.getLinkLabel(), this.getLinkTrackId()]);

    return {
      type: ContractActionType.Button,
      linkLabel,
      linkTrackId,
      onClick: (): void => {
        this.editContract(engagement);
      },
    };
  }

  private getLinkLabel(): Promise<string> {
    return firstValueFrom(this.fmsService.translateAsync<string>("contractActions.editContract.linkLabel"));
  }

  private getLinkTrackId(): Promise<string> {
    return Promise.resolve("contractAction-editContract");
  }

  private editContract(engagement: AnyEngagement): void {
    const options = {
      width: LARGE_DIALOG_WINDOW_WIDTH,
      panelClass: "large-dialog-padding",
      autoFocus: false,
      restoreFocus: false,
      data: engagement,
    };

    if (engagement instanceof ExternalSavingsEngagement) {
      this.dialog.open(ExternalEngagementComponent, options);
    } else if (engagement instanceof OtherPensionEngagement) {
      if (engagement.contract.replaceOffentligTjenestepensjon) {
        this.dialog.open(OffentligTjenestepensjonEngagementComponent, options);
      } else {
        this.dialog.open(OtherPensionEngagementComponent, options);
      }
    }
  }
}
