<div class="summary-table" [class.summary-table-responsive]="responsive">
  @if (showSimulationParameters) {
    <div class="summary-row mobile-only">
      <div class="summary-label">
        {{ "engagement.label.totalPayout" | fms }}
      </div>
      <div class="summary-value">
        @if (!hasError) {
          {{ getTotalPayout() | currencyFormatterPipe: "start" }}
        } @else {
          <app-amount-unavailable></app-amount-unavailable>
        }
      </div>
    </div>
    <div class="summary-row mobile-only">
      <div class="summary-label">
        {{ "engagement.label.payoutDuration" | fms }}
      </div>
      <div class="summary-value">
        @if (!hasError) {
          {{ getDuration() }}
        } @else {
          <app-amount-unavailable></app-amount-unavailable>
        }
      </div>
    </div>
  }

  @if (isPeriodic()) {
    <div class="summary-row">
      <div class="summary-label">
        {{ "engagement.label.periodicSaving" | fms }}
      </div>
      <div class="summary-value">
        {{ engagement.contract.periodicDeposit | currencyFormatterPipe: "start" }}
      </div>
    </div>
  }
</div>
