<div class="promo-wrapper">
  <div class="promo-picture-wrapper">
    <img class="promo-picture" src="{{ promoPicture }}" />
  </div>

  <div>
    @if (promoTitle) {
      <h4>{{ promoTitle }}</h4>
    }
    <p [class.p-style]="promoTitle" class="promo-content">{{ promoContent }}</p>
  </div>
</div>
