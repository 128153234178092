<div class="container">
  <section class="top">
    <app-information-message [alertKey]="AlertKey.Dashboard"></app-information-message>
    <h1>{{ "dashboard.title" | fms }}</h1>
  </section>

  <section class="middle">
    @if (firstName$ | async; as firstName) {
      <h2 data-hj-suppress>
        {{ "dashboard.name" | fms: { args: { firstName: firstName | formatName } } }}
      </h2>
    }
    <div>
      <app-dashboard-promo-cards></app-dashboard-promo-cards>
      <app-dashboard-cards></app-dashboard-cards>
    </div>
    @if (isQualifiedFromSmartAccount$ | async) {
      <app-smart-account-billboard></app-smart-account-billboard>
    }
  </section>

  <aside>
    <app-dashboard-link-list></app-dashboard-link-list>
  </aside>

  <section class="bottom">
    <app-purchase-assistance-consent></app-purchase-assistance-consent>
  </section>
</div>
