<div class="agreements-chart-component">
  <div class="chart-top">
    <app-chart-legend></app-chart-legend>
    @if (showStorebrandOnly) {
      <app-storebrand-only-checkbox class="hide-only-on-print"></app-storebrand-only-checkbox>
    }
  </div>

  <app-chart
    [oneToOne]="true"
    [options$]="options$"
    [series$]="series$"
    [description]="'agreementsChart.description' | fms"
    (initialized)="highchartsInitialized($event)"
    [showLifelongText]="true"
  ></app-chart>
</div>
